/* eslint-disable */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import 'dayjs/locale/fr';
import 'react-quill/dist/quill.snow.css';

import { getCategory } from 'api/category';
import {
  getCollect,
  getCollectAmount,
  getCollectSupporters,
  getCollectVotes,
  voteCollect,
  getAllCollectSupporters,
  getCollectDonations,
  getDons,
} from 'api/collection';
import {getMyProfile} from 'api/user';
import {
  createArticles,
  deleteArticle,
  get,
  getArticles,
  updateArticles,
} from 'api/user';
import clsx from 'clsx';
import CountDownTimer from 'components/Countdown';
import Header from 'components/Header';
import IconCheckCircle from 'components/icons/IconCheckCircle';
import IconSetting from 'components/icons/IconSetting';
import InternalEditor from 'components/InternalEditor';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import ReactQuill from 'react-quill';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

dayjs.extend(relativeTime);
dayjs.locale('fr');
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const NewsInformationSchema = Yup.object().shape({
  title: Yup.string().required('Veuillez entrer le titre'),
  explanation: Yup.string().required('Veuillez complèter la description'),
});

const Collection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const slug = location.pathname?.split('/')[2]?.toLowerCase();
  const [collection, setCollection] = useState({});
  const [articles, setArticles] = useState({
    news: [],
    errorLoadingNews: [false, ''],
  });
  const [description, setDescrption] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');
  const [message, setMessage] = useState([]);
  const [progress, setProgress] = useState(0);
  const [groupName, setGroupName] = useState('');
  const [likes, setLikes] = useState(0);
  const [supporters, setSupporters] = useState([]);
  const [color, setColor] = useState('');

  const [user, setUser] = useState({});
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [userError, setUserError] = useState(null);

  const [articleToModify, setArticleToModify] = useState(null);
  const [amountRemainingToBeCollected, setAmountRemainingToBeCollected] = useState(0);
  const [collectedAmount, setCollectedAmount] = useState(0);
  const [isVoting, setIsVoting] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);
  const [expandedArticles, setExpandedArticles] = useState(new Set());
// Ajouter un nouveau state pour les donations détaillées
const [detailedDonations, setDetailedDonations] = useState([]);
const [isLoadingDetailedDonations, setIsLoadingDetailedDonations] = useState(false);
const [detailedDonationsError, setDetailedDonationsError] = useState(null);

  // States pour les supporters
  const [supportersCount, setSupportersCount] = useState(0);
  const [supportersList, setSupportersList] = useState([]);
  const [isLoadingSupporters, setIsLoadingSupporters] = useState(false);
  const [supportersError, setSupportersError] = useState(null);
  
  // State pour les donations
  const [donations, setDonations] = useState([]);
  const [isLoadingDonations, setIsLoadingDonations] = useState(false);
  const [donationsError, setDonationsError] = useState(null);

  // Pagination
  const [supportersPagination, setSupportersPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    perPage: 10,
    total: 0
  });

  const listSlides = useMemo(() => [
    {
      text: <div className='text-left'>
          La tirelire solidaire s&apos;élève à <br />
          <span className="text-red-400 text-2xl font-bold">
            {collection?.total_tirelire}
            {collection?.total_tirelire !== 0 ? '€' : ''}
          </span>
        </div>,
      image: '/assets/little_piggy_bank_1.png',
    },
    {
      text: <div className='text-left'>
      La tirelire solidaire s&apos;élève à <br />
      <span className="text-red-400 text-2xl font-bold">
        {collection?.total_tirelire}
        {collection?.total_tirelire !== 0 ? '€' : ''}
      </span>
    </div>,
      image: '/assets/pinky_piggy_bank_1.png',
    },
    {
      text: <div className='text-left'>
      Remportez la tirelire solidaire: <br />
      <span className="text-red-400 text-2xl font-bold">
        {collection?.total_tirelire}
        {collection?.total_tirelire !== 0 ? '€' : ''}
      </span>
      </div>,
      image: '/assets/tirelire-gif.gif',
    },
  ], [groupName, collection]);

  const [slide, setSlide] = useState(0);
  const [step, setStep] = useState(1);
  const modifyRef = useRef(null);

  const detailToDisplay = (index) => {
    setStep(index);
  };

useEffect(() => {
  if (collectedAmount > 0 && collection?.montant_cagnotte > 0) {
    setProgress((collectedAmount / collection.montant_cagnotte) * 100);
  }
}, [collectedAmount, collection?.montant_cagnotte]);

  function calculateProgress(level, goal) {
    if (level > 0 && goal > 0) setProgress(parseInt((level / goal) * 100, 10));
  }

  const calculateTimeLeft = () => {
    // get last day of the current month
    const lastDay = dayjs(collection.ending_date)
      .add(1, 'second')
      .tz('Europe/Paris')
      .endOf('month')
      .format('YYYY-MM-DDT00:00:00Z');
    const difference = +new Date(lastDay) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
// Ajouter un état pour suivre si une requête est en cours
const [isPendingRequest, setIsPendingRequest] = useState(false);

// Optimiser la fonction fetchDetailedDonations
const fetchDetailedDonations = async () => {
  // Éviter les requêtes multiples si une est déjà en cours
  if (!collection?.id || isPendingRequest) return;
  
  setIsPendingRequest(true);
  setIsLoadingDetailedDonations(true);
  setDetailedDonationsError(null);
  
  try {
    const { data } = await getDons(collection.id);
    if (data?.data) {
      setDetailedDonations(data.data);
    } else {
      throw new Error('Données invalides');
    }
  } catch (error) {
    console.error('Erreur lors du chargement des dons:', error);
    setDetailedDonationsError("Une erreur est survenue lors du chargement des dons");
  } finally {
    setIsLoadingDetailedDonations(false);
    setIsPendingRequest(false);
  }
};
  const fetchCategory = (catId) => {
    getCategory(catId).then((res) => {
      setGroupName(res.data.data.title);
      setColor(res.data.data.color);
    });
  };

  const getAmount = async (id, goal) => {
    getCollectAmount(id).then((res) => {
      const remaining = goal && goal > 0 
        ? Math.max(0, Number(goal) - Number(res.data.montant || 0)) 
        : 0;
      
      setAmountRemainingToBeCollected(
        `${remaining} ${goal > 0 ? '€' : ''}`
      );
      setCollectedAmount(res.data.montant || 0);
    });
  };

  const fetchVotes = (id) => {
    getCollectVotes(id).then((res) => {
      if (res.data.vote) setLikes(res.data.vote);
    });
  };

  const fetchSupporters = (id) => {
    getCollectSupporters(id).then((res) => {
      if (res.data.nbresupporter) setSupporters(res.data.nbresupporter);
    });
  };

  const getAllArticles = (collectionId) => {
    setLoading(true);
    getArticles(collectionId)
      .then((res) => {
        setLoading(false);
        setArticles({
          news: res?.data?.data,
          errorLoadingNews:
            res?.data?.data.length === 0
              ? [true, 'Aucune actualité disponible']
              : [false, ''],
        });
      })
      .catch((err) => {
        setArticles({
          errorLoadingNews: [
            true,
            "Une erreur s'est produite. Veuillez recharger la page.",
          ],
        });
        setLoading(false);
      });
  };


  const getUser = async () => {
    setIsLoadingUser(true);
    getMyProfile().then((res) => {
      setUser(res.data.user.original.data);
    }).catch((err) => {
      setUserError(err.response.data.message);
    }).finally(() => {
      setIsLoadingUser(false);
    });
  };

  

  useEffect(() => {
    getUser();
  }, []);

  const fetchCollection = () => {
    setLoading(true);
    getCollect(slug)
      .then((res) => {
        // Ajout du log de débogage
        console.log('Détails de la collecte:', {
          rawData: res?.data?.data,
          formattedCollection: {
            id: res?.data?.data?.id,
            name: res?.data?.data?.title,
            categoryId: res?.data?.data?.category_id,
            amountGoal: res?.data?.data?.montant_cagnotte,
            description: res?.data?.data?.description,
            date: res?.data?.data?.created_at,
            ending_date: res?.data?.data?.end_date,
            hasFixedAmount: res?.data?.data?.montant_is_libre === 1,
            video: res?.data?.data?.url_video === 'null' || res?.data?.data?.url_video === null ? '' : res?.data?.data?.url_video,
            userId: res?.data?.data?.users_id,
            publicName: res?.data?.data?.public_name,
            publicCollectedAmount: res?.data?.data?.is_public_collect_amount === 1,
            publicAmountGoal: res?.data?.data?.montant_is_libre === 1,
            collectedAmount: res?.data?.data?.collected_amount || 0,
            total_tirelire: res?.data?.data?.total_tirelire || 0,
            is_active: res?.data?.data?.is_active,
            image: res?.data?.data?.image
          }
        });

        if (res?.data?.data?.is_active === 0) {
          // Modification ici pour afficher les informations de la collecte clôturée
          setCollection({
            id: res?.data?.data?.id,
            name: res?.data?.data?.title,
            categoryId: res.data.data?.category_id,
            amountGoal: res?.data?.data?.montant_cagnotte,
            description: res?.data?.data?.description,
            date: res?.data?.data?.created_at,
            ending_date: res?.data?.data?.end_date,
            hasFixedAmount: res?.data?.data?.montant_is_libre === 1,
            video: res?.data?.data?.url_video === 'null' || res?.data?.data?.url_video === null ? '' : res?.data?.data?.url_video,
            userId: res?.data?.data?.users_id,
            publicName: res?.data?.data?.public_name,
            publicCollectedAmount: res?.data?.data?.is_public_collect_amount === 1,
            publicAmountGoal: res?.data?.data?.montant_is_libre === 1,
            collectedAmount: res?.data?.data?.collected_amount || 0,
            total_tirelire: res?.data?.data?.total_tirelire || 0,
            isClosed: true // Ajout d'un flag pour indiquer que la collecte est clôturée
          });

          // Afficher le message de clôture en haut de la page
          setMessage(
            <div className="bg-amber-50 p-4 rounded-lg mb-6">
              <div className="flex items-center">
                <svg className="h-5 w-5 text-amber-400 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
                <h2 className="text-amber-800 font-medium">Cette collecte est terminée</h2>
              </div>
            </div>
          );

          // Continuer à charger les données normalement
          setImage(res?.data?.data.image);
          fetchCategory(res?.data?.data?.category_id);
          getAmount(res?.data?.data?.id, res?.data?.data?.montant_cagnotte);
          fetchVotes(res?.data?.data?.id);
          fetchSupporters(res?.data?.data?.id);
          getAllArticles(res?.data?.data?.id);
          setLoading(false);

          try {
            if (res?.data?.data?.description !== null && res?.data?.data?.description !== 'null') {
              setDescrption(JSON.parse(res?.data?.data?.description));
            }
          } catch (error) {
            console.error('Erreur parsing description:', error);
          }

          // calculateProgress(
          //   res?.data?.data?.collected_amount || 0,
          //   res?.data?.data?.montant_cagnotte || 0
          // );
        } else {
          setCollection({
            id: res?.data?.data?.id,
            name: res?.data?.data?.title,
            categoryId: res.data.data?.category_id,
            amountGoal: res?.data?.data?.montant_cagnotte,
            description: res?.data?.data?.description,
            date: res?.data?.data?.created_at,
            ending_date: res?.data?.data?.end_date,
            hasFixedAmount: res?.data?.data?.montant_is_libre === 1,
            video:
              res?.data?.data?.url_video === 'null' ||
              res?.data?.data?.url_video === null
                ? ''
                : res?.data?.data?.url_video,
            userId: res?.data?.data?.users_id,
            publicName: res?.data?.data?.public_name,
            publicCollectedAmount:
              res?.data?.data?.is_public_collect_amount === 1,
            publicAmountGoal: res?.data?.data?.montant_is_libre === 1,
            collectedAmount: res?.data?.data?.collected_amount || 0,
            total_tirelire: res?.data?.data?.total_tirelire || 0,
          });

          setImage(res?.data?.data.image);
          fetchCategory(res?.data?.data?.category_id);
          getAmount(res?.data?.data?.id, res?.data?.data?.montant_cagnotte);
          fetchVotes(res?.data?.data?.id);
          fetchSupporters(res?.data?.data?.id);
          getAllArticles(res?.data?.data?.id);

          setLoading(false);
          try {
            if (
              res?.data?.data?.description !== null &&
              res?.data?.data?.description !== 'null'
            )
              setDescrption(JSON.parse(res?.data?.data?.description));
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(
              '🚀 ~ file: collecte.js ~ line 247 ~ .then ~ error',
              error
            );
          }
          // calculateProgress(
          //   res?.data?.data?.collected_amount || 0,
          //   res?.data?.data?.montant_cagnotte || 0
          // );
        }
      })
      .catch((err) => {
        // Ajout du log d'erreur
        console.error('Erreur lors du chargement de la collecte:', err);
        if (err.response.status === 404) {
          setMessage(
            <>
              <img
                src="/assets/collecte-non-disponible.webp"
                alt="not found"
                className="mx-auto max-w-xs"
              />
              <h1 className="mt-10 text-2xl font-bold">
                Nous n&apos;avons pas trouvé cette collecte.
              </h1>

              <p className="mt-4">
                Malheureusement, cette collecte n&apos;existe pas ou a été
                supprimée.
              </p>

              <p className="mt-4">
                Vous pouvez toujours soutenir d&apos;autres collectes.
              </p>

              <br />
              <br />

              <Link
                to="/"
                className="relative mx-auto w-fit items-start justify-center rounded-full bg-primary/90 px-9 py-3 text-xs font-bold leading-tight text-white transition-colors duration-300 hover:scale-105 hover:bg-primary/100 sm:px-3 2xl:px-4"
              >
                Aller à l&apos;accueil
              </Link>
            </>
          );
        } else {
          setMessage([
            false,
            "Une erreur s'est produite. Veuillez recharger la page.",
          ]);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (collection?.id)
      get().then((res) => {
        if (res?.data?.user?.original?.data?.id === collection?.userId) {
          setIsOwner(true);
        }
      });
  }, [collection]);

  useEffect(() => {
    const range = [0, 1, 2];
    const interval = setInterval(() => {
      setSlide(Math.floor(Math.random() * range.length));
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let timer = null;
    if (collection?.ending_date) {
      timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [collection?.ending_date]);

  useEffect(() => {
    if (slug) fetchCollection();
  }, [slug]);

  useEffect(() => {
    const percentageComplete = progress / 100;
    const strokeDashOffsetValue = 100 - percentageComplete * 100;
    const progressBar = document.querySelector('.profile-progress-bar');
    if (progressBar) {
      setTimeout(() => {
        progressBar.style.strokeDashoffset = strokeDashOffsetValue;
      }, 1000);
    }
  }, [progress]);

  // Chargement du nombre total de supporters
  useEffect(() => {
    if (!collection?.id) return;

    const fetchSupportersCount = async () => {
      try {
        const res = await getCollectSupporters(collection.id);
        setSupportersCount(res.data.nbresupporter || 0);
      } catch (error) {
        console.error('Erreur compteur supporters:', error);
      }
    };

    fetchSupportersCount();
  }, [collection?.id]);

  // Chargement de la liste des supporters
  useEffect(() => {
    if (!collection?.id) return;

    const fetchSupportersList = async () => {
      setIsLoadingSupporters(true);
      try {
        const { data } = await getAllCollectSupporters(collection.id);
        setSupportersList(data.data || []);
        setSupportersPagination({
          currentPage: data.meta.pagination.current_page,
          totalPages: data.meta.pagination.total_pages, 
          perPage: data.meta.pagination.per_page,
          total: data.meta.pagination.total
        });
      } catch (error) {
        setSupportersError("Erreur chargement supporters");
      } finally {
        setIsLoadingSupporters(false);
      }
    };

    fetchSupportersList();
  }, [collection?.id]);

  // Chargement des donations
  useEffect(() => {
    if (!collection?.id) return;

    const fetchDonations = async () => {
      setIsLoadingDonations(true);
      try {
        const { data } = await getCollectDonations(collection.id);
        setDonations(data.data || []);
      } catch (error) {
        setDonationsError("Erreur chargement donations");
      } finally {
        setIsLoadingDonations(false);
      }
    };

    fetchDonations();
  }, [collection?.id]);

  const renderDate = (date) => {
    const dateNow = new Date();
    const dateCreated = new Date(date);

    // since n days if within a month
    if (dateNow.getMonth() === dateCreated.getMonth()) {
      const diff = dateNow.getDate() - dateCreated.getDate();
      if (diff === 0) return "Lancé aujourd'hui";
      if (diff === 1) return 'Lancé hier';
      return `Lancé depuis ${diff} jours`;
    }
    if (dateNow.getFullYear() === dateCreated.getFullYear()) {
      const diff = dateNow.getMonth() - dateCreated.getMonth();
      if (diff === 1) return 'Lancé depuis 1 mois';
      return `Lancé depuis ${diff} mois`;
    }
    const diff = dateNow.getFullYear() - dateCreated.getFullYear();
    if (diff === 1) return 'Lancé il y a 1 an';
    return `Lancé il y a ${diff} ans`;
  };

  const openModifyPopup = (id) => {
    setArticleToModify(articles?.news[id]?.id);
    modifyRef.current.setValues({
      title: articles?.news[id]?.titre,
      explanation: articles?.news[id]?.content,
    });
    window.HSOverlay.open(document.getElementById('modify-article'));
  };

  const openDeletePopup = (id) => {
    setArticleToModify(id);
    window.HSOverlay.open(document.getElementById('delete_this_article'));
  };

  const deleteThisArticle = () => {
    if (!loading) {
      setLoading(true);
      setMessage([]);
      deleteArticle(articles.news[articleToModify].id)
        .then(() => {
          setMessage([true, 'Votre actualité a été supprimé']);
          getAllArticles(collection.id);
          setTimeout(() => {
            window.HSOverlay.close(
              document.getElementById('delete_this_article')
            );
            setMessage([]);
          }, 1500);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setMessage([false, 'Une erreur est survenue, veuillez réessayer']);
        });
    }
  };

  const renderSupporter = (supporter) => {
    return (
      <li key={supporter.id} className="mt-5 border-b last:border-b-0 pb-4">
        <figure className="flex items-center">
          <span
            className="bg-center ml-1 mr-3 h-7 w-7 rounded-full bg-cover"
            style={{
              backgroundImage: `url('/assets/profile_user.png')`,
            }}
          />
          <figcaption className="flex-1">
            <div className="flex items-center justify-between">
              <h3 className="text-lg leading-5">
                {supporter.donation_list.original.data[0].mask_identity ? 'Anonyme' : `${supporter.first_name} ${supporter.last_name}`}
              </h3>
              { supporter?.donation_list?.original?.data?.[0] && (
                <span className="text-sm font-medium text-green-600">
                  {supporter.donation_list.original.data[0].mask_amount ? 'Montant masqué' : `${supporter.donation_list.original.data[0].amount.toLocaleString('fr-FR')} €`}
                </span>
              )}
            </div>
            <div className="text-xs text-gray-600">
              {dayjs(supporter.created_at).fromNow()}
              {supporter.commentary && (
                <p className="mt-1 italic">"{supporter.commentary}"</p>
              )}
            </div>
          </figcaption>
        </figure>
      </li>
    );
  };

  // Améliorer la structure de stockage des votes
  const saveVoteToLocalStorage = (collectId) => {
    try {
      const votes = JSON.parse(localStorage.getItem('votedCollects') || '{}');
      votes[collectId] = Date.now();
      localStorage.setItem('votedCollects', JSON.stringify(votes));
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du vote:', error);
    }
  };

  // Vérifier si l'utilisateur peut voter
  const canUserVoteAgain = (collectId) => {
    try {
      const votes = JSON.parse(localStorage.getItem('votedCollects') || '{}');
      const lastVoteTimestamp = votes[collectId];
      
      if (!lastVoteTimestamp) return true;
      
      const now = Date.now();
      const hoursSinceLastVote = (now - lastVoteTimestamp) / (1000 * 60 * 60);
      
      return hoursSinceLastVote >= 24;
    } catch (error) {
      console.error('Erreur lors de la vérification du vote:', error);
      return false;
    }
  };

  // Obtenir le temps restant avant de pouvoir voter à nouveau
  const getTimeUntilNextVote = (collectId) => {
    try {
      const votes = JSON.parse(localStorage.getItem('votedCollects') || '{}');
      const lastVoteTimestamp = votes[collectId];
      
      if (!lastVoteTimestamp) return '';
      
      const now = Date.now();
      const nextVoteTime = lastVoteTimestamp + (24 * 60 * 60 * 1000);
      const remainingMs = nextVoteTime - now;
      
      if (remainingMs <= 0) return '';
      
      const hours = Math.floor(remainingMs / (1000 * 60 * 60));
      const minutes = Math.floor((remainingMs % (1000 * 60 * 60)) / (1000 * 60));
      
      return `Vous pourrez voter à nouveau dans ${hours}h${minutes}m`;
    } catch (error) {
      console.error('Erreur lors du calcul du temps restant:', error);
      return '';
    }
  };

  // Modifier le bouton de vote pour afficher le bon texte
  const getVoteButtonTitle = () => {
    if (collection?.isClosed) return 'Collecte terminée';
    if (isVoting) return 'Vote en cours...';
    if (!canUserVoteAgain(collection?.id)) {
      return getTimeUntilNextVote(collection?.id);
    }
    return 'Voter';
  };

  // Mettre à jour useEffect pour vérifier régulièrement le statut du vote
  useEffect(() => {
    if (collection?.id) {
      const checkVoteStatus = () => {
        const canVote = canUserVoteAgain(collection.id);
        setHasVoted(!canVote);
        setVoteStatus(canVote ? '' : getTimeUntilNextVote(collection.id));
      };

      // Vérifier immédiatement
      checkVoteStatus();

      // Mettre à jour toutes les minutes
      const intervalId = setInterval(checkVoteStatus, 60000);

      return () => clearInterval(intervalId);
    }
  }, [collection?.id]);

  // Modifier le gestionnaire de vote
  const handleVote = async () => {
    if (isVoting || collection?.isClosed) return;
    
    if (!canUserVoteAgain(collection?.id)) {
      const timeRemaining = getTimeUntilNextVote(collection?.id);
      setMessage([false, timeRemaining]);
      setTimeout(() => setMessage([]), 3000);
      return;
    }

    try {
      setIsVoting(true);
      await voteCollect({
        collects_id: collection.id,
      });

      // Mettre à jour le compteur et sauvegarder le vote
      fetchVotes(collection.id);
      saveVoteToLocalStorage(collection.id);
      setHasVoted(true);
      
      setMessage([true, 'Merci pour votre vote !']);
      setTimeout(() => setMessage([]), 2000);
      
      // Mettre à jour le statut du vote
      setVoteStatus(getTimeUntilNextVote(collection.id));
    } catch (error) {
      console.error('Erreur de vote:', error);
      setMessage([false, error.response?.data?.message || 'Une erreur est survenue']);
    } finally {
      setIsVoting(false);
    }
  };

  // Ajouter un état pour le statut du vote
  const [voteStatus, setVoteStatus] = useState('');

  const getVoteStatusText = () => {
    if (isVoting) return 'Vote en cours...';
    if (hasVoted) return 'Merci pour votre vote !';
    return 'Cliquez pour voter';
  };

  const toggleArticleExpansion = (articleId) => {
    setExpandedArticles((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(articleId)) {
        newSet.delete(articleId);
      } else {
        newSet.add(articleId);
      }
      return newSet;
    });
  };

  // Skeleton loader pour les supporters
  const SkeletonSupporter = () => (
    <div className="animate-pulse mt-5 border-b last:border-b-0 pb-4">
      <div className="flex items-center">
        <div className="h-7 w-7 bg-gray-200 rounded-full mr-3"/>
        <div className="flex-1">
          <div className="flex items-center justify-between">
            <div className="h-5 bg-gray-200 rounded w-32"/>
            <div className="h-4 bg-gray-200 rounded w-16"/>
          </div>
          <div className="mt-1">
            <div className="h-3 bg-gray-200 rounded w-24"/>
            <div className="h-3 bg-gray-200 rounded w-48 mt-1"/>
          </div>
        </div>
      </div>
    </div>
  );

  // Skeleton loader pour les articles
  const SkeletonArticle = () => (
    <article className="animate-pulse rounded-lg border border-gray-200 bg-white p-6 shadow-sm mb-6">
      <div className="flex justify-between mb-4">
        <div>
          <div className="h-7 bg-gray-200 rounded w-48 mb-2"/>
          <div className="h-4 bg-gray-200 rounded w-32"/>
        </div>
        <div className="h-8 bg-gray-200 rounded w-24"/>
      </div>
      <div className="space-y-3">
        <div className="h-4 bg-gray-200 rounded w-full"/>
        <div className="h-4 bg-gray-200 rounded w-full"/>
        <div className="h-4 bg-gray-200 rounded w-3/4"/>
      </div>
    </article>
  );

  // Rendu des donations
  const renderDonation = (donation) => {
    return (
      <li key={donation.id} className="mt-5 border-b last:border-b-0">
        <figure className="flex items-center">
          <span
            className="bg-center ml-1 mr-3 h-7 w-7 rounded-full bg-cover"
            style={{
              backgroundImage: `url('/assets/profile_user.png')`,
            }}
          />
          <figcaption>
            <h3 className="mb-2 text-lg leading-5">
              {donation.mask_identity ? 'Anonyme' : `${donation.first_name} ${donation.last_name}`}
            </h3>
            <p className="text-xs">
              {dayjs(donation.created_at).fromNow()}
              {donation.mask_amount ? '' : (
                <>
                  {' • '}
                  <span className="font-medium text-green-600">
                    {donation.amount}{donation.amount ? ' €' : ''}
                  </span>
                </>
              )}
              {donation.mask_amount || donation.pourboire === 0 ? '' : (
                <>
                  {' • '}
                  <span className="font-medium">
                    Pourboire:
                  </span>
                  {' '}
                  <span className="font-medium text-green-600">
                    {donation.pourboire}{donation.pourboire ? ' €' : ''}
                  </span>
                </>
              )}
              {donation.commentary && (
                <span className="block mt-1 text-gray-600 italic">
                  "{donation.commentary}"
                </span>
              )}
            </p>
          </figcaption>
        </figure>
      </li>
    );
  };

  // Skeleton loader pour les donations
  const SkeletonDonation = () => (
    <div className="animate-pulse mt-5 border-b last:border-b-0 pb-4">
      <div className="flex items-center">
        <div className="h-7 w-7 bg-gray-200 rounded-full mr-3"/>
        <div className="flex-1">
          <div className="h-5 bg-gray-200 rounded w-36 mb-2"/>
          <div className="h-3 bg-gray-200 rounded w-28"/>
        </div>
      </div>
    </div>
  );

  // Loader component
  const LoaderSpinner = () => (
    <div className="flex justify-center items-center h-40">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
    </div>
  );

  // Améliorer le composant de dialogue des dons
  const DonationsDialog = ({ isOpen, onClose, donations, isLoading, error }) => {
    // Utiliser useRef pour suivre si le dialogue est monté
    const isMounted = useRef(false);

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }, []);

    // Charger les dons uniquement à l'ouverture initiale du dialogue
    useEffect(() => {
      if (isOpen && collection?.id && !detailedDonations.length && !isPendingRequest) {
        fetchDetailedDonations();
      }
    }, [isOpen, collection?.id]);

    return (
      <div
        className={`fixed inset-0 z-50 flex items-center justify-center ${
          isOpen ? 'visible' : 'hidden'
        }`}
      >
        <div className="fixed inset-0 bg-black/50" onClick={onClose} />
        <div className="relative w-full max-w-lg rounded-lg bg-white p-6 shadow-xl">
          {/* Header */}
          <div className="mb-4 flex items-center justify-between border-b pb-4">
            <h3 className="text-lg font-medium">Dons les plus élevés</h3>
            <button
              onClick={onClose}
              className="rounded-full p-1 hover:bg-gray-100"
            >
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>

          {/* Content avec loading state amélioré */}
          <div className="max-h-[60vh] overflow-y-auto">
            {isLoading ? (
              <div className="flex flex-col items-center justify-center py-12">
                <div className="h-10 w-10 animate-spin rounded-full border-4 border-primary border-t-transparent" />
                <p className="mt-4 text-sm text-gray-500">Chargement des dons...</p>
              </div>
            ) : error ? (
              <div className="flex items-center justify-center py-8 text-red-500">
                <svg className="mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>{error}</span>
              </div>
            ) : donations?.length === 0 ? (
              <div className="flex flex-col items-center justify-center py-12">
                <svg className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                </svg>
                <p className="mt-4 text-gray-500">Aucun don pour le moment</p>
              </div>
            ) : (
              <ul className="space-y-4">
                {donations.map((donation) => (
                  <li
                    key={donation.id}
                    className="flex justify-between border-b pb-4 last:border-0"
                  >
                    <div className="flex-1">
                      <div className="flex items-center">
                        <span
                          className="mr-3 h-8 w-8 rounded-full bg-cover bg-center"
                          style={{ backgroundImage: "url('/assets/profile_user.png')" }}
                        />
                        <div>
                          <p className="font-medium">
                            {donation.mask_identity ? 'Anonyme' : `${donation.first_name} ${donation.last_name}`}
                          </p>
                          <p className="text-sm text-gray-500">
                            {dayjs(donation.created_at).fromNow()}
                          </p>
                        </div>
                      </div>
                      {donation.commentary && (
                        <p className="mt-2 text-sm italic text-gray-600">
                          "{donation.commentary}"
                        </p>
                      )}
                    </div>
                    {!donation.mask_amount && (
                      <div className="flex flex-col gap-1">
                        <p className="font-semibold">Montant</p>
                        <span className="shrink-0 rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800">
                          {donation.amount.toLocaleString('fr-FR')} €
                        </span>
                      </div>
                     )}
                    {!donation.mask_amount && donation?.pourboire !== 0 && (
                      <div className="flex flex-col gap-1">
                        <p className="font-semibold">Pourboire</p>
                        <span className="ml-4 shrink-0 rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800">
                          {donation.pourboire.toLocaleString('fr-FR')} €
                        </span>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  };

  // État pour gérer le dialogue
  const [isDonationsDialogOpen, setIsDonationsDialogOpen] = useState(false);

  // Remplacer le bouton existant par celui-ci
  const DonationsButton = () => (
    <button
      onClick={() => setIsDonationsDialogOpen(true)}
      className="mt-4 inline-flex items-center justify-center gap-2 rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white hover:bg-primary/90"
    >
      <svg className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
        <path
          fillRule="evenodd"
          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
          clipRule="evenodd"
        />
      </svg>
      Voir les plus gros dons
    </button>
  );

  // Section des donations
  const DonationsSection = () => (
    <section className="mt-4">
      {isLoadingDonations ? (
        <div className="space-y-4">
          {[...Array(5)].map((_, index) => (
            <SkeletonDonation key={index} />
          ))}
        </div>
      ) : donationsError ? (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-800">{donationsError}</p>
            </div>
          </div>
        </div>
      ) : donations.length === 0 ? (
        <div className="py-4 text-center">
          <span className="text-gray-500">Aucun don pour le moment</span>
        </div>
      ) : (
        <ul className="space-y-6 list-none">
          {donations.map(renderDonation)}
        </ul>
      )}

      {donations.length > 0 && (
        <DonationsButton />
      )}
      <DonationsDialog
        isOpen={isDonationsDialogOpen}
        onClose={() => setIsDonationsDialogOpen(false)}
        donations={detailedDonations}
        isLoading={isLoadingDetailedDonations}
        error={detailedDonationsError}
      />
    </section>
  );

  // Articles Section
  const ArticlesSection = () => (
    <section>
      {/* Add news button */}
      {isOwner && (
        <div className="mb-8 flex justify-center">
          <button
            type="button"
            className="inline-flex items-center justify-center gap-2 rounded-full bg-primary px-6 py-3 text-sm font-semibold text-white transition-all hover:bg-primary/90"
            style={{ backgroundColor: color || '#EAEDF1' }}
            data-hs-overlay="#i-contribute"
          >
            <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4"/>
            </svg>
            Ajouter une actualité
          </button>
        </div>
      )}

      {articles?.errorLoadingNews[0] ? (
        <p className="text-center text-gray-500">{articles?.errorLoadingNews[1]}</p>
      ) : articles.isLoading ? (
        <div className="space-y-8">
          {[...Array(3)].map((_, index) => (
            <SkeletonArticle key={index} />
          ))}
        </div>
      ) : (
        <div className="space-y-8">
          {articles.news.map((theNew, articleIndex) => (
            <article
              key={theNew.id}
              className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm"
            >
              <header className="mb-4 flex items-start justify-between">
                <div>
                  <h2 className="mb-2 text-2xl font-bold text-gray-900">
                    {theNew.titre}
                  </h2>
                  <time
                    className="text-sm text-gray-500"
                    dateTime={theNew.created_at}
                  >
                    {dayjs(theNew.created_at).format(
                      'DD MMMM YYYY à HH:mm'
                    )}
                  </time>
                </div>

                {isOwner && (
                  <div className="flex gap-2">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-full bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 transition-colors hover:bg-gray-200"
                      onClick={() => openModifyPopup(articleIndex)}
                    >
                      Modifier
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center rounded-full bg-red-100 px-4 py-2 text-sm font-medium text-red-700 transition-colors hover:bg-red-200"
                      onClick={() => openDeletePopup(articleIndex)}
                    >
                      Supprimer
                    </button>
                  </div>
                )}
              </header>

              <div className="relative">
                <div
                  className={clsx(
                    'prose prose-sm max-w-none text-gray-700',
                    !expandedArticles.has(theNew.id) &&
                      'line-clamp-3'
                  )}
                  dangerouslySetInnerHTML={{
                    __html: theNew.content,
                  }}
                />

                {theNew.content.length > 300 && (
                  <button
                    type="button"
                    onClick={() => {
                      toggleArticleExpansion(theNew.id);
                    }}
                    className="mt-2 text-sm font-medium text-primary hover:text-primary/80"
                  >
                    {expandedArticles.has(theNew.id)
                      ? 'Voir moins'
                      : 'Voir plus'}
                  </button>
                )}
              </div>
            </article>
            ))}
          </div>
      )}
    </section>
  );

  // Ajout des composants de pagination
  const PaginationButton = ({ onClick, disabled, children, className = '' }) => (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        inline-flex items-center px-4 py-2 text-sm font-medium
        ${disabled 
          ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
          : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-900'}
        border border-gray-300 rounded-md
        ${className}
      `}
    >
      {children}
    </button>
  );


  console.log(progress)
  const Pagination = ({ 
    currentPage, 
    totalPages, 
    onPageChange,
    isLoading,
    totalItems,
    itemsPerPage 
  }) => {
    // Calculer la plage de pages à afficher
    const getPageRange = () => {
      const range = [];
      const delta = 2; // Nombre de pages à afficher de chaque côté
      
      for (
        let i = Math.max(2, currentPage - delta);
        i <= Math.min(totalPages - 1, currentPage + delta);
        i++
      ) {
        range.push(i);
      }

      // Ajouter les ellipses si nécessaire
      if (currentPage - delta > 2) {
        range.unshift('...');
      }
      if (currentPage + delta < totalPages - 1) {
        range.push('...');
      }

      // Toujours afficher la première et dernière page
      if (totalPages > 1) {
        range.unshift(1);
        range.push(totalPages);
      }

      return range;
    };

    const pageRange = getPageRange();
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);

    return (
      <div className="mt-6 flex flex-col items-center gap-4 sm:flex-row sm:justify-between">
        <div className="text-sm text-gray-700">
          Affichage de{' '}
          <span className="font-medium">{startItem}</span>
          {' '}-{' '}
          <span className="font-medium">{endItem}</span>
          {' '}sur{' '}
          <span className="font-medium">{totalItems}</span>
          {' '}résultats
        </div>

        <nav className="flex items-center gap-2" aria-label="Pagination">
          {/* Bouton Précédent */}
          <PaginationButton
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1 || isLoading}
          >
            <svg className="h-4 w-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Précédent
          </PaginationButton>

          {/* Numéros de page */}
          <div className="hidden sm:flex gap-2">
            {pageRange.map((page, index) => (
              page === '...' ? (
                <span key={`ellipsis-${index}`} className="px-4 py-2">...</span>
              ) : (
                <PaginationButton
                  key={page}
                  onClick={() => onPageChange(page)}
                  disabled={page === currentPage || isLoading}
                  className={page === currentPage ? 'bg-primary text-white hover:bg-primary/90' : ''}
                >
                  {page}
                </PaginationButton>
              )
            ))}
          </div>

          {/* Bouton Suivant */}
          <PaginationButton
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages || isLoading}
          >
            Suivant
            <svg className="h-4 w-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </PaginationButton>
        </nav>
      </div>
    );
  };

  // Mise à jour de la section des supporters pour utiliser la pagination
  const SupportersSection = () => {
    const handlePageChange = async (newPage) => {
      await fetchSupportersList(collection.id, newPage);
    };

    return (
      <section className="mt-4">
        {isLoadingSupporters ? (
          <div className="space-y-4">
            {[...Array(5)].map((_, index) => (
              <SkeletonSupporter key={index} />
            ))}
          </div>
        ) : supportersError ? (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-800">{supportersError}</p>
              </div>
            </div>
          </div>
        ) : supportersList.length === 0 ? (
          <div className="py-4 text-center">
            <span className="text-gray-500">Aucun supporter pour le moment</span>
          </div>
        ) : (
          <>
            <ul className="space-y-6 list-none">
              {supportersList.map(renderSupporter)}
            </ul>

            <Pagination
              currentPage={supportersPagination.currentPage}
              totalPages={supportersPagination.totalPages}
              onPageChange={handlePageChange}
              isLoading={isLoadingSupporters}
              totalItems={supportersPagination.total}
              itemsPerPage={supportersPagination.perPage}
            />
          </>
        )}
      </section>
    );
  };

  // Mise à jour de la fonction fetchSupportersList
  const fetchSupportersList = async (collectId, page = 1) => {
    if (!collectId || isPendingRequest) return;
    
    setIsPendingRequest(true);
    setIsLoadingSupporters(true);
    setSupportersError(null);

    try {
      const { data: response } = await getAllCollectSupporters(collectId, page);
      
      if (response?.data) {
        setSupportersList(response.data);
        setSupportersPagination({
          currentPage: response.meta.pagination.current_page,
          totalPages: response.meta.pagination.total_pages,
          perPage: response.meta.pagination.per_page,
          total: response.meta.pagination.total
        });
      }
    } catch (error) {
      setSupportersError("Erreur lors du chargement des supporters");
      console.error('Erreur lors du chargement de la liste des supporters:', error);
    } finally {
      setIsLoadingSupporters(false);
      setIsPendingRequest(false);
    }
  };

  // Ajouter près des autres states
  const [showTooltip, setShowTooltip] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Ajouter cette fonction pour détecter le mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title={`${collection?.name || 'Collecte'} - KOUDPOUSS`} />
      <Header />
      
      {/* Message de collecte terminée */}
      {collection?.isClosed && (
        <div className="bg-amber-50 p-4 rounded-lg mb-6">
          <div className="flex items-center">
            <svg className="h-5 w-5 text-amber-400 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
            <h2 className="text-amber-800 font-medium">Cette collecte est terminée</h2>
          </div>
        </div>
      )}

      <section className="min-h-[40vh] bg-white px-6 pb-10 pt-6">
        {loading && (
          <div className="relative text-center">
            <div
              className={`mx-auto text-center transition-colors delay-300
                ${loading ? 'text-primary' : 'text-transparent'}`}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-live="polite"
                aria-busy="true"
                aria-labelledby="title-08a desc-08a"
                className="mx-auto h-16 w-16 md:h-24 md:w-24"
              >
                <title id="title-08a">loading</title>
                <path
                  d="M7 8H3V16H7V8Z"
                  className="animate animate-bounce fill-current "
                />
                <path
                  d="M14 8H10V16H14V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.2s]"
                />
                <path
                  d="M21 8H17V16H21V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.4s]"
                />
            </svg>
      </div>
          </div>
        )}
        {/* My Collection */}
        {!loading && collection?.id && (
          <>
            <h1 className=" text-center text-2xl font-bold">
              {collection?.name}
            </h1>
            <section className="flex flex-col xl:flex-row items-start justify-start gap-6 mt-6">
              <figure className="relative  flex grow flex-col  w-full xl:w-1/2">
                <div
                  className="rounded-lg px-4 py-6 shadow-sm"
                  style={{
                    background: color || '#EAEDF1',
                  }}
                >
                  {/* Header avec catégorie et paramètres */}
                  <div className="flex items-center justify-between ">
                    <h2 className="text-white text-lg lg:text-xl font-medium line-clamp-1">
                      {groupName}
                    </h2>
                    {localStorage.getItem('persist:root') && isOwner && (
                      <a
                        className="cursor-pointer text-white hover:opacity-80"
                        href={`/modifier-ma-collecte/${slug}`}
                      >
                        <IconSetting />
                      </a>
                    )}
                  </div>

                  {/* Profil et statistiques - Layout amélioré */}
                  <div className="flex flex-col md:flex-row items-center justify-between gap-6 ">
                   
                   
                  </div>
                </div>
                {collection?.video ? (
  <div className="relative">
    {/* Container vidéo */}
    <div className="mx-auto w-full h-[300px] md:h-[400px] rounded-lg overflow-hidden">
      <ReactPlayer
        width="100%"
        height="100%"
        controls
        url={collection.video}
      />
    </div>
    
    {/* Overlay avec profil et date */}
    <div className='absolute bottom-0 left-0 w-full bg-black/40 backdrop-blur-sm p-4 rounded-b-lg shadow-lg'>
      {/* Stats Container */}
      <div className="flex flex-wrap justify-center md:justify-end items-center gap-4 w-full">
        {/* Profil et date */}
        <div className="flex flex-col w-full items-center md:items-start gap-4">
          <div className="flex items-center gap-4 w-full">
            <div 
              className="relative group"
              onMouseEnter={() => !isMobile && setShowTooltip(true)}
              onMouseLeave={() => !isMobile && setShowTooltip(false)}
              onClick={() => isMobile && setShowTooltip(!showTooltip)}
            >
              <div
                className="h-8 w-8 md:h-12 md:w-12 rounded-full border-2 bg-center bg-cover bg-no-repeat cursor-help shadow-lg transition-all duration-300 hover:scale-105 hover:border-white/80"
                style={{
                  backgroundImage: user?.avatar ? `url(${user.avatar})` : 'url(/assets/profile_user.png)',
                  borderColor: 'rgba(255,255,255,0.6)',
                }}
              />
              {/* Tooltip */}
              {showTooltip && user?.biography && (
                <div className="absolute z-20 px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700 -translate-x-1/2 left-1/2 bottom-full mb-4 max-w-[16rem] sm:max-w-xs">
                  <div className="relative">
                    {isMobile && (
                      <div className="flex justify-end border-b border-gray-700 pb-1 mb-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowTooltip(false);
                          }}
                          className="p-1 text-white/80 hover:text-white"
                        >
                          <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg>
                        </button>
                      </div>
                    )}
                    <p className="text-sm leading-relaxed break-words">{user?.biography}</p>
                  </div>
                  <div className="tooltip-arrow absolute w-3 h-3 bg-gray-900 dark:bg-gray-700 transform rotate-45 left-1/2 -translate-x-1/2 -bottom-1.5" />
                </div>
              )}
            </div>

            <div className="flex flex-col">
              <div className="flex items-center gap-3 flex-wrap">
                <h3 className="font-bold text-white text-sm  md:text-md">
                  {collection.publicName}
                </h3>
                {user?.verification_status === 'approved' && (
                  <div className="flex items-center bg-white/20 px-2 py-1 rounded-full">
                    <IconCheckCircle color="white" dimension="14px" />
                    <span className="ml-1.5 text-xs text-white/90">Vérifié</span>
                  </div>
                )}
              </div>
              {/* Date stylisée */}
              <div className="flex items-center mt-1.5">
                <span className="inline-flex items-center text-sm text-white/90 bg-white/10 rounded-full px-3 py-1">
                  {renderDate(collection?.date)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Progression si nécessaire */}
        {collection?.publicCollectedAmount && collection?.montant_cagnotte && (
          <div className="relative flex items-center justify-center bg-white/15 rounded-xl p-3 shadow-lg">
            <svg className="h-16 w-16 transform -rotate-90" viewBox="0 0 36 36">
              <circle
                cx="18"
                cy="18"
                r="16"
                fill="none"
                className="stroke-current text-white/20"
                strokeWidth="2.5"
              />
              <circle
                cx="18"
                cy="18"
                r="16"
                fill="none"
                stroke="white"
                strokeWidth="2.5"
                strokeDasharray={`${progress} 100`}
                className="transition-all duration-1000 ease-out"
              />
            </svg>
            <div className="absolute flex flex-col items-center">
              <span className="text-lg font-bold text-white">
                {progress}%
              </span>
              <span className="text-xs text-white/80">Atteint</span>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
) : null}

{image && !collection?.video && (
  <div className="relative w-full h-[300px] md:h-[400px]" style={{ aspectRatio: '16/9' }}>
    <span
      className="absolute inset-0 block bg-center w-full h-full bg-cover bg-no-repeat rounded-lg"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover', // Ensure the image covers the entire container
        backgroundPosition: 'center', // Center the image
        filter: 'brightness(1.1) contrast(1.2)', // Adjust brightness and contrast for clarity
      }}
    />
                    <div className='absolute bottom-0 left-0 w-full bg-black/40 backdrop-blur-sm p-4 rounded-lg shadow-lg'>
                      {/* Stats Container */}
                      <div className="flex flex-wrap justify-center md:justify-end items-center gap-4 w-full">
                        {/* Progression */}
                       {/* Profil et date */}
                       <div className="flex flex-col w-full items-center md:items-start gap-4">
                        <div className="flex items-center gap-4 w-full">
                          <div 
                            className="relative group"
                            onMouseEnter={() => !isMobile && setShowTooltip(true)}
                            onMouseLeave={() => !isMobile && setShowTooltip(false)}
                            onClick={() => isMobile && setShowTooltip(!showTooltip)} // Toggle sur mobile
                          >
                            <div
                              className="h-8 w-8 md:h-12 md:w-12 rounded-full border-2 bg-center bg-cover bg-no-repeat cursor-help shadow-lg transition-all duration-300 hover:scale-105 hover:border-white/80"
                              style={{
                                backgroundImage: user?.avatar ? `url(${user.avatar})` : 'url(/assets/profile_user.png)',
                                borderColor: 'rgba(255,255,255,0.6)',
                              }}
                            />
                            {/* Tooltip avec classes Flowbite */}
                            {showTooltip && user?.biography && (
                              <div className="absolute z-20 px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700 -translate-x-1/2 left-1/2 bottom-full mb-4 max-w-[16rem] sm:max-w-xs">
                                <div className="relative">
                                  {isMobile && (
                                    <div className="flex justify-end border-b border-gray-700 pb-1 mb-2">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowTooltip(false);
                                        }}
                                        className="p-1 text-white/80 hover:text-white"
                                      >
                                        <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                      </button>
                                    </div>
                                  )}
                                  <p className="text-sm leading-relaxed break-words">{user?.biography}</p>
                                </div>
                                {/* Flèche du tooltip */}
                                <div className="tooltip-arrow absolute w-3 h-3 bg-gray-900 dark:bg-gray-700 transform rotate-45 left-1/2 -translate-x-1/2 -bottom-1.5" />
                              </div>
                            )}
                          </div>

                          <div className="flex flex-col">
                            <div className="flex items-center gap-3 flex-wrap">
                              <h3 className="font-bold text-white text-sm md:text-md">
                                {collection.publicName}
                              </h3>
                              {user?.verification_status === 'approved' && (
                                <div className="flex items-center bg-white/20 px-2 py-1 rounded-full">
                                  <IconCheckCircle color="white" dimension="14px" />
                                  <span className="ml-1.5 text-xs text-white/90">Vérifié</span>
                                </div>
                              )}
                            </div>
                            {/* Date stylisée */}
                            <div className="flex items-center mt-1.5">
                              <span className="inline-flex items-center text-sm text-white/90 bg-white/10 rounded-full px-3 py-1">
                              
                                {renderDate(collection?.date)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                        {collection?.publicCollectedAmount && collection?.montant_cagnotte && (
                          <div className="relative flex items-center justify-center bg-white/15 rounded-xl p-3 shadow-lg">
                            <svg
                              className="h-16 w-16 transform -rotate-90"
                              viewBox="0 0 36 36"
                            >
                              <circle
                                cx="18"
                                cy="18"
                                r="16"
                                fill="none"
                                className="stroke-current text-white/20"
                                strokeWidth="2.5"
                              />
                              <circle
                                cx="18"
                                cy="18"
                                r="16"
                                fill="none"
                                stroke="white"
                                strokeWidth="2.5"
                                strokeDasharray={`${progress} 100`}
                                className="transition-all duration-1000 ease-out"
                              />
                            </svg>
                            <div className="absolute flex flex-col items-center">
                              <span className="text-lg font-bold text-white">
                                {progress}%
                              </span>
                              <span className="text-xs text-white/80">Atteint</span>
                            </div>
                          </div>
                        )}

                       
                      </div>
                    </div>
                  </div>
                )}
               
                {/* Bouton de vote */}
                <figcaption className="flex justify-center items-center bg-white/95 backdrop-blur-sm py-4 px-8 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg">
                  <button
                    onClick={handleVote}
                    disabled={isVoting || !canUserVoteAgain(collection.id) || collection?.isClosed}
                    className={clsx(
                      'group flex items-center gap-3 transition-all duration-300',
                      (isVoting || !canUserVoteAgain(collection.id) || collection?.isClosed)
                        ? 'cursor-not-allowed opacity-60'
                        : 'hover:opacity-95 hover:scale-102 active:scale-98'
                    )}
                    type="button"
                    title={getVoteButtonTitle()}
                  >
                    <div className="relative">
                      <img
                        src="/assets/thumbs_up.png"
                        alt="Aime"
                        className={clsx(
                          'w-10 h-10 object-contain transition-all duration-300 drop-shadow-md group-hover:drop-shadow-lg',
                          { 'animate-bounce': isVoting }
                        )}
                      />
                      <div className="absolute -top-1 -left-4 bg-primary text-white font-medium text-sm rounded-full min-w-[1.5rem] h-6 flex items-center justify-center px-2 shadow">
                        {likes}
                      </div>
                    </div>
                    
                    <div className="flex flex-col items-start">
                      <span className="font-semibold text-base text-gray-800 transition-colors group-hover:text-primary">
                        {likes} vote{likes !== 1 && 's'}
                      </span>
                      <span className="text-sm text-gray-500 text-left">
                        {collection?.isClosed 
                          ? 'Collecte terminée'
                          : (voteStatus || (canUserVoteAgain(collection.id) ? 'Cliquez pour voter' : ''))}
                      </span>
                    </div>
                  </button>
                </figcaption>
              </figure>

              {/* Reste du contenu avec espacement amélioré */}
              <div className="w-full xl:w-1/2">
                {/* Amount collected */}
                {collection?.publicCollectedAmount && (
                  <figure className="flex items-center justify-between bg-white rounded-t-xl border-2 border-primary/20 px-6 py-5 hover:border-primary/40 hover:shadow-lg transition-all duration-300">
                    <div className="flex items-center gap-5">
                      <div className="bg-gradient-to-br from-primary/10 to-primary/20 p-4 rounded-xl shadow-inner">
                        <img
                          src="/assets/gain.png"
                          alt="Montant collecté"
                          title="Montant collecté" 
                          className="w-9 h-9 object-contain drop-shadow-sm"
                        />
                      </div>
                      <figcaption className="flex flex-col">
                        <span className="text-sm text-gray-500 font-medium tracking-wide">
                          Montant collecté
                        </span>
                        <strong className="text-3xl font-bold bg-gradient-to-r from-primary to-red-500 bg-clip-text text-transparent drop-shadow-sm">
                          {Number(collectedAmount || 0).toLocaleString('fr-FR')} €
                        </strong>
                      </figcaption>
                    </div>
                    {/* <div className="hidden sm:flex items-center gap-2 bg-primary/10 px-4 py-2 rounded-full">
                      <svg className="w-4 h-4 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                      </svg>
                      <span className="text-sm font-medium text-primary">Progression</span>
                    </div> */}
                  </figure>
                )}
                {/* I support with a donation */}
                <div className={collection?.isClosed ? 'opacity-50 pointer-events-none' : ''}>
                  <div className="items-start py-5 pl-5 pr-5 sm:flex" style={{ background: color || '#EAEDF1' }}>
                    <figure className="mb-4 flex shrink-0 justify-center sm:mb-0 sm:mr-7 sm:mt-8">
                      <img src="/assets/send_love.png" alt="Love message" title="Je supporte avec un don" />
                    </figure>
                    <aside className="mx-auto text-center text-white">
                      <h2 className="text-xl font-bold">
                        Je supporte avec un don
                      </h2>
                      <div className="flex flex-wrap items-center justify-center gap-4 text-red-500">
                        <Link
                          className="mt-5 w-20 cursor-pointer rounded-full bg-white py-2 text-center font-bold duration-300 hover:scale-105"
                          to={`/offrir-un-don/${slug}`}
                          state={{ amount: 20 }}
                        >
                          20 €
                        </Link>
                        <Link
                          className="mt-5 w-20 cursor-pointer rounded-full bg-white py-2 text-center font-bold duration-300 hover:scale-105"
                          to={`/offrir-un-don/${slug}`}
                          state={{ amount: 50 }}
                        >
                          50 €
                        </Link>
                        <Link
                          className="mt-5 w-20 cursor-pointer rounded-full bg-white py-2 text-center font-bold duration-300 hover:scale-105"
                          to={`/offrir-un-don/${slug}`}
                          state={{ amount: 100 }}
                        >
                          100 €
                        </Link>
                        <Link
                          className="mt-5 w-20 cursor-pointer rounded-full bg-white py-2 text-center font-bold duration-300 hover:scale-105"
                          to={`/offrir-un-don/${slug}`}
                          state={{ amount: 500 }}
                        >
                          500 €
                        </Link>
                        <Link
                          className="mt-5 cursor-pointer rounded-full bg-white px-5 py-2 font-bold text-red-500 duration-300 hover:scale-105"
                          to={`/offrir-un-don/${slug}`}
                        >
                          Choisir un montant
                        </Link>
                      </div>
                    </aside>
                  </div>
                </div>
                {/* Supporters */}
                <figure className="my-3 flex items-center justify-center">
                  <img
                    src="/assets/team.png"
                    alt="Supporters"
                    title="Supporters"
                  />
                  <figcaption className="ml-3 text-xl font-bold text-black">
                    {supportersCount} Supporters
                  </figcaption>
                </figure>
                {/* Amounts resume */}
                <ul className="mx-auto flex max-w-xs flex-col justify-center sm:mx-0 sm:max-w-8xl sm:flex-row xl:justify-between">
                  {/* Remaining to be collected */}
                  {collection?.publicCollectedAmount && collection?.amountGoal ? (
                    <li className="shrink-0 rounded-md px-3 py-4 text-center" style={{backgroundColor: color}}>
                      <figure>
                        <img
                          src="/assets/make_donation.png"
                          alt="Donner"
                          title="Reste à collecter"
                          className="mx-auto h-10"
                        />
                        <figcaption className="font-bold text-white">
                          <h3 className="my-3 text-xl">
                            {amountRemainingToBeCollected}
                          </h3>
                          <p>Reste à collecter</p>
                        </figcaption>
                      </figure>
                    </li>
                  ) : null}
                  {/* Amount collected */}
                  <li
                    className="bg-right my-4  flex w-full items-center border-2  rounded-xl object-right bg-cover bg-no-repeat  py-4 text-center font-bold sm:mx-2 sm:my-0"
                    style={{
                      backgroundImage: `url(${listSlides[slide].image})`,
                      backgroundPosition: 'right',
                      backgroundSize: 'contain',
                      borderColor: color || '#cbd5ff4d',
                    
                    }}
                  >
                    <figure>
                      <figcaption className="flex w-1/2 px-3">
                        <h3 className="leading-6">
                         
                            <>
                              {listSlides[slide].text}
                            </>
                         
                        </h3>
                      </figcaption>
                    </figure>
                  </li>
                  {/* Goal */}
                  {collection?.publicCollectedAmount && collection?.amountGoal ? (
                    <li className="shrink-0 rounded-md bg-primary px-3 py-4 text-white sm:w-36">
                      <figure className="flex flex-col items-center justify-center text-center font-bold">
                        <img
                          src="/assets/target.png"
                          alt="But"
                          title="But"
                          className="h-10"
                        />
                        <figcaption>
                          <h3 className="my-3 text-xl">
                            {collection?.amountGoal}
                            {collection?.amountGoal > 0 ? '€' : ''}
                          </h3>
                          <p>But</p>
                        </figcaption>
                      </figure>
                    </li>
                  ) : null}
                </ul>
              </div>
            </section>

            {/* Navigation des sections avec espacement amélioré */}
            <section className="flex flex-col justify-between xl:flex-row gap-4 md:gap-10 mt-4 md:mt-12">
              {/* Dynamic Render */}
              <section className="xl:w-2/3">
                {/* Actions */}
                <ul className="flex flex-wrap gap-4 sm:flex-row my-4">
                  <li className="w-full sm:w-auto">
                    <button
                      onClick={() => detailToDisplay(1)}
                      type="button"
                      className={`rounded-full ${
                        step === 1 ? 'text-black' : 'text-white'
                      } w-full sm:w-36 px-6 py-3 text-center duration-300 hover:scale-105`}
                      style={{
                        backgroundColor:
                          step === 1 ? '#cbd5ff4d' : color || '#cbd5ff4d',
                      }}
                    >
                      Description
                    </button>
                  </li>
                  <li className="w-full sm:w-auto">
                    <button
                      onClick={() => detailToDisplay(2)}
                      type="button"
                      className={`rounded-full ${
                        step === 2 ? 'text-black' : 'text-white'
                      } w-full sm:w-36 px-6 py-3 text-center duration-300 hover:scale-105`}
                      style={{
                        backgroundColor:
                          step === 2 ? '#cbd5ff4d' : color || '#cbd5ff4d',
                      }}
                    >
                      Actualité
                    </button>
                  </li>
                  <li className="w-full sm:w-auto">
                    <button
                      onClick={() => detailToDisplay(3)}
                      type="button"
                      className={`rounded-full ${
                        step === 3 ? 'text-black' : 'text-white'
                      } w-full sm:w-36 px-6 py-3 text-center duration-300 hover:scale-105`}
                      style={{
                        backgroundColor:
                          step === 3 ? '#cbd5ff4d' : color || '#cbd5ff4d',
                      }}
                    >
                      Supporters
                    </button>
                  </li>
                </ul>
                {/* Description */}
                {step === 1 && (
                  <article>
                    {/* An article */}
                    {collection?.description ? (
                      <InternalEditor
                        content={description}
                        setContent={(ctn) => setDescrption(ctn)}
                        isPreview
                      />
                    ) : (
                      <p className="text-left">Aucune description</p>
                    )}
                  </article>
                )}
                {/* News */}
                {step === 2 && <ArticlesSection />}
                {/* Supporters */}
                {step === 3 && (
                  <SupportersSection />
                )}
              </section>
              {/* Donations */}
              <section className="mx-auto w-full xl:mx-0 xl:max-w-sm">

               {/* Date */}
{collection?.ending_date ? (
  <CountDownTimer
    date={dayjs(collection?.ending_date)
      .tz('Europe/Paris')
      .format('YYYY-MM-DD')} // Simplifier le format de date
  />
) : (
  <></>
)}

                <h2 className="my-4 text-xl font-bold">Derniers dons</h2>
                {/* Latest Donations Collected */}
                <DonationsSection />
                {/* Share-Follow */}
                <ul className="mt-2 flex items-end justify-end">
                  <li className="relative mr-6">
                    <button
                      type="button"
                      className="group relative inline-block"
                      data-hs-overlay="#share-modal"
                    >
                      <img
                        src="/assets/share.png"
                        title="Partager"
                        alt="Partager"
                        className="cursor-pointer"
                      />
                      <span className="absolute left-10 top-3 font-semibold text-white">
                        Partager
                      </span>
                    </button>

                    {/* Modal */}
                    <div
                      id="share-modal"
                      className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden"
                    >
                      <div className="m-3 mt-0 min-h-[calc(100%-3.5rem)] opacity-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg">
                        <div className="flex flex-col rounded-xl border bg-white shadow-sm">
                          {/* Header */}
                          <div className="flex items-center justify-between border-b px-4 py-3">
                            <h3 className="font-bold text-gray-800">
                              Partager la collecte
                            </h3>
                            <button
                              type="button"
                              className="hs-dropdown-toggle inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-md text-sm text-gray-500 transition-all hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white"
                              data-hs-overlay="#share-modal"
                            >
                              <span className="sr-only">Fermer</span>
                              <svg
                                className="h-3.5 w-3.5"
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </button>
                          </div>

                          {/* Body */}
                          <div className="overflow-y-auto p-4">
                            <div className="flex justify-center gap-4">
                              <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                  window.location.href
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col items-center gap-2 rounded-lg p-3 hover:bg-gray-100"
                              >
                                <img
                                  src="/assets/facebook.png"
                                  alt="Facebook"
                                  className="h-10 w-10"
                                />
                                <span className="text-sm">Facebook</span>
                              </a>

                              <a
                                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                                  `Découvrez la collecte "${collection?.name}" sur KOUDPOUSS`
                                )}&url=${encodeURIComponent(
                                  window.location.href
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col items-center gap-2 rounded-lg p-3 hover:bg-gray-100"
                              >
                                <img
                                  src="/assets/X.png"
                                  alt="Twitter"
                                  className="h-10 w-10"
                                />
                                <span className="text-sm">Twitter</span>
                              </a>

                              <a
                                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                                  window.location.href
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col items-center gap-2 rounded-lg p-3 hover:bg-gray-100"
                              >
                                <img
                                  src="/assets/linkedin.png"
                                  alt="LinkedIn"
                                  className="h-10 w-10"
                                />
                                <span className="text-sm">LinkedIn</span>
                              </a>

                              <a
                                href={`https://wa.me/?text=${encodeURIComponent(
                                  `Découvrez la collecte "${collection?.name}" sur KOUDPOUSS ${window.location.href}`
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-col items-center gap-2 rounded-lg p-3 hover:bg-gray-100"
                              >
                                <img
                                  src="/assets/whatsapp.png"
                                  alt="WhatsApp"
                                  className="h-10 w-10"
                                />
                                <span className="text-sm">WhatsApp</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="cursor-pointer">
                    <img
                      src="/assets/follow.png"
                      title="Suivre"
                      alt="Aimer et suivre"
                    />
                  </li>
                </ul>
              </section>
            </section>
            {/* Alert */}
            <a
              className="mx-auto flex items-center justify-center"
              href="mailto:info@koud-pouss.com"
              type="button"
            >
              <img
                src="/assets/alert.png"
                alt="Alerte"
                title="Signaler cette collecte"
                className="mr-2 h-5"
              />
              <span>Signaler cette collecte</span>
            </a>
          </>
        )}
        {/* Create news */}
        <Formik
          initialValues={{
            title: '',
            explanation: '',
          }}
          validationSchema={NewsInformationSchema}
          onSubmit={(values, { resetForm }) => {
            if (!loading) {
              setLoading(true);
              createArticles({
                collects_id: collection.id,
                titre: values.title,
                content: values.explanation,
              })
                .then(() => {
                  setMessage([
                    true,
                    'Votre actualité a été ajoutée avec succès',
                  ]);
                  resetForm();
                  getAllArticles(collection.id);

                  setTimeout(() => {
                    window.HSOverlay.close(
                      document.getElementById('i-contribute')
                    );
                    setMessage([]);
                  }, 2000);
                })
                .catch((error) => {
                  setMessage([
                    false,
                    "Une erreur est survenue lors de l'ajout de l'actualité",
                  ]);
                  console.error(
                    "Erreur lors de l'ajout de l'actualité:",
                    error
                  );
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <div
                id="i-contribute"
                className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden"
              >
                <div className="m-3 mt-0 flex min-h-[calc(100vh-3.5rem)] items-center opacity-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-4xl">
                  <div className="relative flex w-full flex-col rounded-xl bg-white shadow-lg">
                    {/* Header */}
                    {isOwner && (
                      <div className="flex items-center justify-between border-b px-4 py-3">
                        <h3 className="text-xl font-medium text-gray-800">
                          Ajouter une actualité
                        </h3>
                        <button
                          type="button"
                          className="hs-dropdown-toggle inline-flex h-8 w-8 items-center justify-center rounded-md text-sm text-gray-500 hover:text-gray-700"
                          data-hs-overlay="#i-contribute"
                        >
                          <span className="sr-only">Fermer</span>
                          <svg
                            className="h-4 w-4"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                          </svg>
                        </button>
                      </div>
                    )}

                    {/* Body */}
                    <div className="p-4 sm:p-7">
                      {/* Message de feedback */}
                      {message.length > 0 && (
                        <div
                          className={`mb-4 rounded-md p-4 ${
                            message[0]
                              ? 'bg-green-50 text-green-800'
                              : 'bg-red-50 text-red-800'
                          }`}
                        >
                          <div className="flex">
                            <div className="flex-shrink-0">
                              {message[0] ? (
                                <svg
                                  className="h-5 w-5 text-green-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  className="h-5 w-5 text-red-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </div>
                            <div className="ml-3">
                              <p className="text-sm font-medium">
                                {message[1]}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Formulaire */}
                      <div className="space-y-5">
                        {/* Titre */}
                        <div>
                          <span
                            htmlFor="title"
                            className="mb-2 block text-sm font-medium text-gray-800"
                          >
                            Titre de l&apos;actualité
                          </span>
                          <Field
                            name="title"
                            placeholder="Entrez le titre..."
                            className="block w-full rounded-lg border border-gray-200 px-4 py-3 text-sm focus:border-primary focus:ring-primary"
                          />
                          {errors.title && touched.title && (
                            <p className="mt-2 text-sm text-red-500">
                              {errors.title}
                            </p>
                          )}
                        </div>

                        {/* Editeur */}
                        <div className="editor-container">
                          <span className="mb-2 block text-sm font-medium text-gray-800">
                            Contenu
                          </span>
                          <div className="rounded-lg border border-gray-200">
                            <ReactQuill
                              theme="snow"
                              value={values?.explanation || ''}
                              onChange={(content) =>
                                setFieldValue('explanation', content)
                              }
                              modules={{
                                toolbar: {
                                  container: [
                                    [{ header: [1, 2, false] }],
                                    ['bold', 'italic', 'underline'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['link', 'image'],
                                    ['clean'],
                                  ],
                                },
                              }}
                              formats={[
                                'header',
                                'bold',
                                'italic',
                                'underline',
                                'list',
                                'bullet',
                                'link',
                                'image',
                              ]}
                              style={{
                                backgroundColor: 'white',
                                borderRadius: '0.5rem',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Footer */}
                    <div className="flex items-center justify-end gap-x-2 border-t bg-gray-50 px-4 py-3">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-semibold text-gray-800 hover:bg-gray-50 disabled:opacity-50"
                        data-hs-overlay="#i-contribute"
                      >
                        Annuler
                      </button>
                      <button
                        type="submit"
                        className="inline-flex items-center justify-center gap-2 rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-semibold text-white hover:bg-primary/90 disabled:opacity-50"
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <span className="h-3.5 w-3.5 animate-spin rounded-full border-2 border-white border-t-transparent" />
                            Chargement...
                          </>
                        ) : (
                          'Publier'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {message.length > 0 && (
                <div
                  className={`fixed bottom-4 left-1/2 -translate-x-1/2 transform rounded-md px-4 py-2 text-sm ${
                    message[0]
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}
                >
                  {message[1]}
                </div>
              )}
            </Form>
          )}
        </Formik>
        {/* Modify news */}
        <Formik
          innerRef={modifyRef}
          initialValues={{
            title: '',
            explanation: '',
          }}
          validationSchema={NewsInformationSchema}
          onSubmit={(values, { resetForm }) => {
            if (!loading) {
              updateArticles({
                id: articleToModify,
                titre: values.title,
                content: values.explanation,
              })
                .then(() => {
                  setMessage([true, 'Votre actualité a été mise à jour']);
                  resetForm();
                  getAllArticles(collection.id);
                  setTimeout(() => {
                    window.HSOverlay.close(
                      document.getElementById('modify-article')
                    );
                    setMessage([]);
                  }, 1500);
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                  setMessage([
                    false,
                    'Une erreur est survenue, veuillez réessayer',
                  ]);
                });
            }
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <div
                id="modify-article"
                className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden"
              >
                <div className="m-3 mt-0 flex min-h-[calc(100vh-3.5rem)] items-center opacity-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-4xl">
                  <div className="relative flex w-full flex-col rounded-xl bg-white shadow-lg">
                    {/* Header */}
                    <div className="flex items-center justify-between border-b px-4 py-3">
                      <h3 className="text-xl font-medium text-gray-800">
                        Modifier l&apos;actualité
                      </h3>
                      <button
                        type="button"
                        className="hs-dropdown-toggle inline-flex h-8 w-8 items-center justify-center rounded-md text-sm text-gray-500 hover:text-gray-700"
                        data-hs-overlay="#modify-article"
                      >
                        <span className="sr-only">Fermer</span>
                        <svg
                          className="h-4 w-4"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                        </svg>
                      </button>
                    </div>

                    {/* Body */}
                    <div className="p-4 sm:p-7">
                      {/* Message de feedback */}
                      {message.length > 0 && (
                        <div
                          className={`mb-4 rounded-md p-4 ${
                            message[0]
                              ? 'bg-green-50 text-green-800'
                              : 'bg-red-50 text-red-800'
                          }`}
                        >
                          <div className="flex">
                            <div className="flex-shrink-0">
                              {message[0] ? (
                                <svg
                                  className="h-5 w-5 text-green-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  className="h-5 w-5 text-red-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              )}
                            </div>
                            <div className="ml-3">
                              <p className="text-sm font-medium">
                                {message[1]}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Formulaire */}
                      <div className="space-y-5">
                        {/* Titre */}
                        <div>
                          <span
                            htmlFor="title"
                            className="mb-2 block text-sm font-medium text-gray-800"
                          >
                            Titre de l&apos;actualité
                          </span>
                          <Field
                            name="title"
                            placeholder="Entrez le titre..."
                            className="block w-full rounded-lg border border-gray-200 px-4 py-3 text-sm focus:border-primary focus:ring-primary"
                          />
                          {errors.title && touched.title && (
                            <p className="mt-2 text-sm text-red-500">
                              {errors.title}
                            </p>
                          )}
                        </div>

                        {/* Editeur */}
                        <div className="editor-container">
                          <span className="mb-2 block text-sm font-medium text-gray-800">
                            Contenu
                          </span>
                          <div className="rounded-lg border border-gray-200">
                            <ReactQuill
                              theme="snow"
                              value={values?.explanation || ''}
                              onChange={(content) =>
                                setFieldValue('explanation', content)
                              }
                              modules={{
                                toolbar: {
                                  container: [
                                    [{ header: [1, 2, false] }],
                                    ['bold', 'italic', 'underline'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['link', 'image'],
                                    ['clean'],
                                  ],
                                },
                              }}
                              formats={[
                                'header',
                                'bold',
                                'italic',
                                'underline',
                                'list',
                                'bullet',
                                'link',
                                'image',
                              ]}
                              style={{
                                backgroundColor: 'white',
                                borderRadius: '0.5rem',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Footer */}
                    <div className="flex items-center justify-end gap-x-2 border-t bg-gray-50 px-4 py-3">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center gap-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-semibold text-gray-800 hover:bg-gray-50 disabled:opacity-50"
                        data-hs-overlay="#modify-article"
                      >
                        Annuler
                      </button>
                      <button
                        type="submit"
                        className="inline-flex items-center justify-center gap-2 rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-semibold text-white hover:bg-primary/90 disabled:opacity-50"
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <span className="h-3.5 w-3.5 animate-spin rounded-full border-2 border-white border-t-transparent" />
                            Chargement...
                          </>
                        ) : (
                          'Modifier'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {/* Delete */}
        <div
          id="delete_this_article"
          className="hs-overlay fixed left-0 top-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden"
        >
          <div className="m-3 mt-0 opacity-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="flex flex-col rounded-xl border bg-white shadow-sm">
              {/* Header */}
              <div className="flex items-center justify-between border-b px-4 py-3">
                <h3 className="text-lg font-medium text-gray-800">
                  Confirmer la suppression
                </h3>
                <button
                  type="button"
                  className="hs-dropdown-toggle inline-flex h-8 w-8 items-center justify-center rounded-md text-sm text-gray-500 transition-all hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white"
                  data-hs-overlay="#delete_this_article"
                >
                  <span className="sr-only">Fermer</span>
                </button>
              </div>

              {/* Body */}
              <div className="p-4">
                <p className="text-gray-800">
                  Êtes-vous sûr de vouloir supprimer l&apos;article{' '}
                  <strong>
                    {articleToModify !== null &&
                    articles?.news?.[articleToModify]?.titre
                      ? `"${articles.news[articleToModify].titre}"`
                      : ''}
                  </strong>{' '}
                  ?
                  <br />
                  Cette action est irréversible.
                </p>
              </div>

              {/* Footer */}
              <div className="flex justify-end gap-2 border-t px-4 py-3">
                <button
                  type="button"
                  className="rounded-md px-4 py-2 text-gray-500 hover:text-gray-700"
                  data-hs-overlay="#delete_this_article"
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className={`rounded-md bg-red-600 px-4 py-2 text-white hover:bg-red-700 ${
                    loading ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                  onClick={deleteThisArticle}
                  disabled={loading}
                >
                  {loading ? (
                    <span className="flex items-center gap-2">
                      Suppression...
                    </span>
                  ) : (
                    'Supprimer'
                  )}
                </button>
              </div>

              {message.length > 0 && (
                <div
                  className={`mx-4 mb-3 rounded-md p-2 text-center text-sm ${
                    message[0]
                      ? 'bg-green-50 text-green-700'
                      : 'bg-red-50 text-red-700'
                  }`}
                >
                  {message[1]}
                </div>
              )}
            </div>
          </div>
        </div>
        {!loading && !collection?.id && (
          <div className="w-full text-center">{message}</div>
        )}
      </section>
    </div>
  );
};

export default Collection;
