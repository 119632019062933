/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */

import { getPageCollects } from 'api/collection';
import Activity from 'components/Activity';
import ActivityWireFrame from 'components/ActivityWireframe';
import Banner from 'components/Banner';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const Collectes = () => {
  // const navigate = useNavigate();
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [title, setTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const [perPage, setPerPage] = useState(10);

  const fetchCollections = () => {
    getPageCollects(title, page, perPage)
      .then((res) => {
        setPagination(res.data.meta.pagination);
        const list = res?.data?.data.map((collection) => {
          return {
            id: collection.id,
            title: collection.title,
            categoryId: collection.category_id,
            subTitle: collection?.title,
            amountGoal: collection?.montant_cagnotte,
            hasFixedAmount: collection?.montant_is_libre === 1,
            slug: collection?.slug,
            image: collection?.image,
            video:
              collection?.url_video === 'null' || collection?.url_video === null
                ? ''
                : collection?.url_video,
            publicCollectedAmount: collection?.is_public_collect_amount === 1,
            is_active: collection?.is_active,
            publicAmountGoal: collection?.montant_is_libre === 1,
          };
        });

        if (page === 1) {
          setCollections(list);
        } else {
          setCollections((prevCollections) =>
            _.uniqBy([...prevCollections, ...list], 'id')
          );
        }
        // setCollections(_.uniqBy([...collections, ...list], 'id'));
        setErrorMessage('');
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setCollections([]);
        setErrorMessage(e.response?.data?.message || 'Une erreur est survenue');
      });
  };

  const resetFilters = () => {
    setTitle('');
    setPage(1);
    fetchCollections();
  };

  useEffect(() => {
    fetchCollections();
  }, [page]);

  return (
    <div className="container mx-auto max-w-7xl px-4 md:px-8 lg:px-24">
      <Helmet title="Collectes - KOUDPOUSS" />
      <Banner
        image="collecte-page-banner.jpg"
        currentPage={3}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
      />
      <div className="bg-white px-4 py-10 md:px-8 lg:px-12">
        <h1 className="mb-10 text-2xl font-semibold md:text-3xl">
          Toutes les collectes
        </h1>
        <form
          className="flex w-full flex-col items-center gap-4 md:flex-row md:gap-6"
          onSubmit={(e) => {
            e.preventDefault();
            setPage(1);
            fetchCollections();
          }}
        >
          <div className="relative w-full md:w-[300px]">
            <input
              type="text"
              placeholder="Rechercher une collecte"
              value={title}
              className="h-[50px] w-full rounded-full border-2 border-slate-300 px-4 py-0 pr-16"
              onChange={(e) => setTitle(e.target.value)}
            />
            <button
              type="button"
              onClick={resetFilters}
              className="absolute right-2 top-1/2 flex h-[40px] w-[40px] -translate-y-1/2 items-center justify-center rounded-full border-2 border-primary transition-colors hover:bg-primary/10"
              aria-label="Réinitialiser les filtres"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-primary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <button
            type="submit"
            className="button-glassmorphism h-[50px] w-36 rounded-full text-white"
          >
            Filtrer
          </button>
        </form>
        <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
          {collections?.length > 0 &&
            collections.map((collection) => <Activity data={collection} />)}

          {loading &&
            [0, 1, 2, 3].map((i) => <ActivityWireFrame key={`spc_${i}`} />)}
          {errorMessage.length > 0 && (
            <div className="col-span-4 text-center">{errorMessage}</div>
          )}
        </div>
        {!loading && pagination.total !== collections.length && (
          <div className="mt-8 flex justify-center">
            <button
              type="button"
              className="rounded-full bg-primary px-4 py-2 text-sm font-semibold text-white"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Charger plus
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Collectes;
