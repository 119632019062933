/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
import { register } from 'api/user';
import Header from 'components/Header';
import { fr } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import useOutsideClick from 'utils/useOutsideClick';
import * as Yup from 'yup';

import { TOKEN } from '../api';

const ConfirmModal = (props) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.setIsShowing(false);
        setTimeout(() => {
          props.navigate('/se-connecter');
        }, 500);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        props.setIsShowing(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    const html = document.querySelector('html');

    if (html) {
      if (props.isShowing && html) {
        html.style.overflowY = 'hidden';

        const focusableElements =
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

        const modal = document.querySelector('#modal'); // select the modal by it's id

        const firstFocusableElement =
          modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal

        const focusableContent = modal.querySelectorAll(focusableElements);

        const lastFocusableElement =
          focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

        document.addEventListener('keydown', (e) => {
          if (e.keyCode === 27) {
            props.setIsShowing(false);
          }

          const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

          if (!isTabPressed) {
            return;
          }

          if (e.shiftKey) {
            // if shift key pressed for shift + tab combination
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus(); // add focus for the last focusable element
              e.preventDefault();
            }
          } else {
            // if tab key is pressed
            if (document.activeElement === lastFocusableElement) {
              // if focused has reached to last focusable element then focus first focusable element after pressing tab
              firstFocusableElement.focus(); // add focus for the first focusable element
              e.preventDefault();
            }
          }
        });

        firstFocusableElement.focus();
      } else {
        html.style.overflowY = 'visible';
      }
    }
  }, [props.isShowing]);

  return (
    <>
      <div />
      {props.isShowing && typeof document !== 'undefined' ? (
        <div
          className="fixed left-0 top-0 z-20 flex h-screen w-screen items-center justify-center bg-slate-300/20 backdrop-blur-sm"
          aria-labelledby="header-3a content-3a"
          aria-modal="true"
          tabIndex="-1"
          role="dialog"
        >
          {/*    <!-- Modal --> */}
          <div
            ref={wrapperRef}
            className="flex max-h-[90vh] w-11/12 max-w-xl flex-col gap-6 overflow-hidden rounded bg-white p-6 text-slate-500 shadow-xl shadow-slate-700/10"
            id="modal"
            role="document"
          >
            {/*        <!-- Modal header --> */}
            <header id="header-3a" className="flex items-center gap-4">
              <h3 className="flex-1 text-xl font-medium text-slate-700">
                Hello, vous y êtes presque !
              </h3>
              <button
                type="button"
                onClick={() => {
                  props.setIsShowing(false);
                  setTimeout(() => {
                    props.navigate('/se-connecter');
                  }, 500);
                }}
                className="hover:secondary inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-5 text-sm font-medium tracking-wide text-secondary transition duration-300 hover:bg-secondary/25 focus:bg-secondary/30 focus:text-secondary focus-visible:outline-none disabled:cursor-not-allowed disabled:text-secondary/30 disabled:shadow-none disabled:hover:bg-transparent"
                aria-label="close dialog"
              >
                <span className="relative only:-mx-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    role="graphics-symbol"
                    aria-labelledby="title-79 desc-79"
                  >
                    <title id="title-79">Close</title>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </button>
            </header>
            {/*        <!-- Modal body --> */}
            <div id="content-3a" className="flex-1 overflow-auto">
              <p>
                Il ne reste plus qu&apos;à valider votre compte en cliquant sur
                le lien contenu dans le mail que nous venons de vous envoyer à
                l&apos;adresse : {props.mail}
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

function calculateAge(birthday) {
  const ageDifMs = Date.now() - birthday;
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState([]);
  const [birthDate, setBirthDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [acceptCGU, setAcceptCGU] = useState(false);
  const [acceptNewsletter, setAcceptNewsletter] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [data, setData] = useState();

  const formRef = useRef();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email incorrect')
      .required('Veuillez saisir votre email'),
    birthDate: Yup.string()
      .required('Veuillez renseigner votre date de naissance')
      // a leas 18 years old
      .test(
        'birthday',
        'Vous devez avoir au moins 18 ans pour vous inscrire',
        (val) => {
          return calculateAge(new Date(val)) > 18;
        }
      ),
    // only if birthdate is valid
    acceptMoreThan18: Yup.boolean().when('birthDate', {
      is: (val) => calculateAge(new Date(val)) > 18,
      then: Yup.boolean().oneOf(
        [true],
        'Vous devez confirmer avoir plus de 18 ans'
      ),
    }),
  });

  const registerUser = (user, resetForm) => {
    setMessage([]);

    setData(user);
    setLoading(true);
    register(user)
      .then(() => {
        setStep(1);
        setIsShowing(true);
        setLoading(false);
        resetForm();
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          setMessage([
            false,
            'Il semblerait que cette adresse email soit déjà utilisée. Réessayer avec une autre adresse',
          ]);
        } else {
          setMessage([false, 'Une erreur s’est produite. Veuillez réessayer.']);
        }
        setLoading(false);
      });
  };

  const openCalendar = () => {
    setShowCalendar(true);
  };

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const calendarDateRef = useOutsideClick(closeCalendar);

  useEffect(() => {
    if (TOKEN) navigate('/');
  }, [TOKEN]);

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Header />
      <Helmet title="Créer un compte - KOUDPOUSS" />
      <section className="bg-white">
        <div className="flex min-h-screen justify-center p-8 px-4 lg:px-24 md:p-16 lg:p-24">
          <div className="hidden rounded-l-[60px] bg-[#F8B03A] bg-cover bg-no-repeat bg-blend-multiply lg:block lg:w-2/5">
            <div className="-ml-24 flex h-full flex-col justify-center">
              <img src="/assets/logo-.png" className="w-2/3" alt="logo" />
            </div>
          </div>

          <div className="mx-auto flex w-full items-center p-8 px-4 lg:w-3/5 lg:px-12">
            <div className="w-full">
              <div className="flex h-full flex-row justify-center">
                <img
                  src="/assets/red-envelope.png"
                  className="w-auto scale-110"
                  alt="logo"
                />
              </div>

              <div className="mx-auto mt-4 max-w-md text-center">
                <h1 className="mt-16 text-2xl font-bold text-black">
                  S’enregistrer
                </h1>
                <br />

                <Formik
                  ref={formRef}
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    birthDate: '',
                    password: '',
                    confirmPassword: '',
                    acceptMoreThan18: false,
                    publicName: '',
                  }}
                  validationSchema={RegisterSchema}
                  onSubmit={(values, { setErrors, resetForm }) => {
                    if (!loading) {
                      if (step === 1) {
                        setStep(2);
                      } else if (step === 2) {
                        let errors = {};
                        if (!values.firstName) {
                          errors = {
                            ...errors,
                            firstName: 'Veuillez saisir votre prénom',
                          };
                        }

                        if (!values.lastName) {
                          errors = {
                            ...errors,
                            lastName: 'Veuillez saisir votre nom',
                          };
                        }

                        if (!values.publicName) {
                          errors = {
                            ...errors,
                            publicName: 'Veuillez saisir votre nom public',
                          };
                        }

                        setErrors(errors);

                        if (
                          values.lastName &&
                          values.firstName &&
                          values.publicName
                        ) {
                          setStep(3);
                        }
                      } else if (step === 3) {
                        setSubmitted(true);
                        if (values.password.length < 8) {
                          setErrors({
                            password:
                              'Le mot de passe doit contenir au moins 8 caractères',
                          });
                        } else if (!/[A-Z]/.test(values.password)) {
                          setErrors({
                            password:
                              'Le mot de passe doit contenir au moins une majuscule',
                          });
                        } else if (!/[+=!@#$%^&*]/.test(values.password)) {
                          setErrors({
                            password:
                              'Le mot de passe doit contenir au moins un caractère spécial',
                          });
                        } else if (values.password !== values.confirmPassword) {
                          setErrors({
                            confirmPassword:
                              'Les mots de passe ne correspondent pas',
                          });
                        } else if (acceptCGU && !loading)
                          registerUser(
                            {
                              email: values.email,
                              date_nais: values.birthDate,
                              password: values.password,
                              name: values.firstName,
                              surname: values.lastName,
                              public_name: values.publicName,
                            },
                            resetForm
                          );
                      }
                    }
                  }}
                  classNam="w-full"
                >
                  {({ errors, touched, setFieldValue }) => (
                    <Form className="flex w-full flex-col space-y-4">
                      {step === 1 && (
                        <>
                          <Field
                            name="email"
                            type="email"
                            placeholder="Saisissez votre mail"
                            className="relative mx-auto w-full items-start justify-center rounded-full bg-[#F8B03A] p-6 text-base leading-tight text-white placeholder:text-white"
                          />
                          {errors.email && touched.email ? (
                            <div className="text-red-400">{errors.email}</div>
                          ) : null}

                          <div
                            ref={calendarDateRef}
                            role="button"
                            onClick={openCalendar}
                            className="relative flex w-full flex-col items-start overflow-x-auto sm:overflow-x-visible"
                            aria-hidden="true"
                          >
                            <div className="w-full">
                              <Field
                                name="birthDate"
                                type="date"
                                placeholder="Veuillez saisir votre date de naissance"
                                className="relative mx-auto w-full items-start justify-center rounded-full bg-[#F8B03A] p-6 pr-12 text-base leading-tight text-white placeholder:text-white"
                              />
                              {showCalendar && (
                                <Calendar
                                  className="rounded shadow-md shadow-slate-200 sm:left-0 sm:top-full sm:z-10 lg:absolute"
                                  date={birthDate}
                                  maxDate={new Date()}
                                  onShownDateChange={(date) => {
                                    const currentDate = new Date(date);
                                    const currentBirthDate = new Date(date);
                                    // set birthDate to current date
                                    currentDate.setDate(
                                      birthDate.getDate() + 1
                                    );
                                    currentBirthDate.setDate(
                                      birthDate.getDate()
                                    );
                                    setFieldValue(
                                      'birthDate',
                                      currentDate.toISOString().split('T')[0]
                                    );
                                    setBirthDate(currentBirthDate);
                                  }}
                                  onChange={(date) => {
                                    const currentDate = new Date(date);
                                    // add 1 day to current date
                                    currentDate.setDate(
                                      currentDate.getDate() + 1
                                    );
                                    setFieldValue(
                                      'birthDate',
                                      currentDate.toISOString().split('T')[0]
                                    );
                                    setBirthDate(date);
                                    setShowCalendar(false);
                                  }}
                                  locale={fr}
                                  color="#FD6E56B7"
                                />
                              )}
                            </div>
                          </div>
                          {errors.birthDate && touched.birthDate ? (
                            <div className="text-red-400">
                              {errors.birthDate}
                            </div>
                          ) : null}

                          <div>
                            <div className="relative flex items-center justify-center">
                              <div className="flex h-5 items-center">
                                <Field
                                  id="hs-checkbox-conditions"
                                  name="acceptMoreThan18"
                                  type="checkbox"
                                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-gray-200 bg-white text-primary transition-colors checked:border-primary checked:bg-primary checked:text-primary checked:hover:border-primary checked:hover:bg-primary focus:outline-none focus:ring-primary checked:focus:border-primary checked:focus:bg-primary checked:focus:ring-primary focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                                  aria-describedby="hs-checkbox-conditions-description"
                                />
                              </div>
                              <label
                                htmlFor="hs-checkbox-archive"
                                className="ml-3"
                              >
                                <span className="block text-sm">
                                  Je certifie être majeur
                                </span>
                              </label>
                            </div>
                            {errors.acceptMoreThan18 &&
                            touched.acceptMoreThan18 ? (
                              <div className="text-red-400">
                                {errors.acceptMoreThan18}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                      {step === 2 && (
                        <>
                          <Field
                            name="lastName"
                            type="text"
                            placeholder="Saisissez votre nom"
                            className="relative mx-auto w-full items-start justify-center rounded-full bg-[#F8B03A] p-6 text-base leading-tight text-white placeholder:text-white"
                          />
                          {errors.lastName && touched.lastName ? (
                            <div className="text-red-400">
                              {errors.lastName}
                            </div>
                          ) : null}

                          <Field
                            name="firstName"
                            type="text"
                            placeholder="Veuillez saisir votre prénom"
                            className="relative mx-auto w-full items-start justify-center rounded-full bg-[#F8B03A] p-6 text-base leading-tight text-white placeholder:text-white"
                          />
                          {errors.firstName && touched.firstName ? (
                            <div className="text-red-400">
                              {errors.firstName}
                            </div>
                          ) : null}

                          <Field
                            name="publicName"
                            type="text"
                            placeholder="Veuillez saisir un nom public"
                            className="relative mx-auto w-full items-start justify-center rounded-full bg-[#F8B03A] p-6 text-base leading-tight text-white placeholder:text-white"
                          />

                          <div className="my-3 flex flex-row justify-between text-xs text-[#808080] md:text-sm">
                            Votre nom public sera visible sur vos collectes.
                          </div>

                          {errors.publicName && touched.publicName ? (
                            <div className="text-red-400">
                              {errors.publicName}
                            </div>
                          ) : null}
                        </>
                      )}
                      {step === 3 && (
                        <>
                          <div className="relative flex w-full">
                            <Field
                              name="password"
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Mot de passe"
                              className="relative mx-auto w-full items-start justify-center rounded-full bg-[#F8B03A] p-6 text-base leading-tight text-white placeholder:text-white"
                            />
                            <button
                              type="button"
                              className="absolute right-6 top-5 text-xl text-white"
                              onClick={() => setShowPassword(!showPassword)}
                              aria-label={
                                showPassword
                                  ? 'Masquer le mot de passe'
                                  : 'Afficher le mot de passe'
                              }
                            >
                              <i
                                className={`fas ${
                                  !showPassword ? 'fa-eye-slash' : 'fa-eye'
                                }`}
                              />
                            </button>
                          </div>
                          <div className="my-3 flex flex-row justify-between text-xs text-[#808080] md:text-sm">
                            <div className="inline-flex items-center">
                              <span className="mr-2 inline-block h-2 w-2 rounded-full bg-[#808080]" />
                              <span className="text-gray-600">
                                &gt; 8 Caractères
                              </span>
                            </div>
                            <div className="inline-flex items-center">
                              <span className="mr-2 inline-block h-2 w-2 rounded-full bg-[#808080]" />
                              <span className="text-gray-600">&gt; 1 Maj</span>
                              <span className="hidden md:block">uscule</span>
                            </div>
                            <div className="inline-flex items-center">
                              <span className="mr-2 inline-block h-2 w-2 rounded-full bg-[#808080]" />
                              <span className="text-gray-600">
                                &gt; 1 Caractère spécial
                              </span>
                            </div>
                          </div>
                          {errors.password && touched.password ? (
                            <div className="text-red-400">
                              {errors.password}
                            </div>
                          ) : null}

                          <div className="relative flex w-full">
                            <Field
                              name="confirmPassword"
                              type={showConfirmPassword ? 'text' : 'password'}
                              placeholder="Confirmez votre mot de passe"
                              className="relative mx-auto w-full items-start justify-center rounded-full bg-[#F8B03A] p-6 text-base leading-tight text-white placeholder:text-white"
                            />
                            <button
                              type="button"
                              className="absolute right-6 top-5 text-xl text-white"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              aria-label={
                                showConfirmPassword
                                  ? 'Masquer la confirmation du mot de passe'
                                  : 'Afficher la confirmation du mot de passe'
                              }
                            >
                              <i
                                className={`fas ${
                                  !showConfirmPassword
                                    ? 'fa-eye-slash'
                                    : 'fa-eye'
                                }`}
                              />
                            </button>
                          </div>
                          {errors.confirmPassword && touched.confirmPassword ? (
                            <div className="text-red-400">
                              {errors.confirmPassword}
                            </div>
                          ) : null}
                        </>
                      )}
                      {step === 3 && (
                        <div className="w-full">
                          <br />
                          <div className="grid space-y-3">
                            <div className="relative flex items-start">
                              <div className="flex h-5 items-center">
                                <input
                                  id="hs-checkbox-conditions"
                                  name="hs-checkbox-conditions"
                                  type="checkbox"
                                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-gray-200 border-slate-500 bg-white text-primary transition-colors checked:border-primary checked:bg-primary checked:text-primary checked:hover:border-primary checked:hover:bg-primary focus:outline-none focus:ring-primary checked:focus:border-primary checked:focus:bg-primary checked:focus:ring-primary focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                                  aria-describedby="hs-checkbox-conditions-description"
                                  onChange={(e) =>
                                    setAcceptCGU(e.target.checked)
                                  }
                                />
                              </div>
                              <label
                                htmlFor="hs-checkbox-archive"
                                className="ml-3"
                              >
                                <span className="block text-sm">
                                  J’ai lu et j’accepte les CGU et la{' '}
                                  <a
                                    className="underline"
                                    href="/politique-de-confidentialite"
                                  >
                                    politique de confidentialité
                                  </a>
                                </span>
                              </label>
                            </div>
                            {!acceptCGU && submitted ? (
                              <div className="text-red-400">
                                Vous devez accepter les CGU et la politique de
                                confidentialité
                              </div>
                            ) : null}

                            <div className="relative flex items-start">
                              <div className="flex h-5 items-center">
                                <input
                                  id="hs-checkbox-newletter"
                                  name="hs-checkbox-newletter"
                                  type="checkbox"
                                  className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-gray-200 border-slate-500 bg-white text-primary transition-colors checked:border-primary checked:bg-primary checked:text-primary checked:hover:border-primary checked:hover:bg-primary focus:outline-none focus:ring-primary checked:focus:border-primary checked:focus:bg-primary checked:focus:ring-primary focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                                  aria-describedby="hs-checkbox-newletter-description"
                                  onChange={(e) =>
                                    setAcceptNewsletter(e.target.checked)
                                  }
                                />
                              </div>
                              <label
                                htmlFor="hs-checkbox-archive"
                                className="ml-3"
                              >
                                <span className="block text-sm">
                                  J’accepte de recevoir ponctuellement des
                                  communiqués de la part de Koud Pouss. Vous
                                  pouvez vous désinscrire à tout moment.
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <br />
                      {message.length > 0 && (
                        <div
                          className={`mb-4 text-center text-sm ${
                            message[0] ? 'text-primary' : 'text-red-500'
                          }`}
                        >
                          {message[1]}
                        </div>
                      )}
                      <div className="flex">
                        {step > 1 && (
                          <button
                            type="button"
                            className="relative mx-auto w-fit items-start justify-center rounded-full bg-[#F8B03A] px-9 py-4 text-base font-bold leading-tight text-white duration-300 hover:scale-105"
                            onClick={handleBack}
                          >
                            Retour
                          </button>
                        )}
                        <button
                          type="submit"
                          className={`relative mx-auto w-fit items-start justify-center rounded-full bg-[#F8B03A] px-9 py-4 text-base font-bold leading-tight text-white
                      ${!loading && 'duration-300 hover:scale-105'}`}
                        >
                          {loading && 'En cours'}
                          {!loading && step === 3 && 'Créer son compte'}
                          {!loading && step < 3 && 'Suivant'}
                        </button>
                      </div>

                      <br />
                      <div className="mt-4 flex flex-col items-center justify-center">
                        <span className="text-sm">
                          Vous avez déjà un compte ?
                        </span>
                        <Link to="/se-connecter">
                          <a className="text-sm text-secondary">
                            Connectez-nous
                          </a>
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConfirmModal
        isShowing={isShowing}
        setIsShowing={setIsShowing}
        mail={data?.email}
        navigate={navigate}
      />
    </div>
  );
};

ConfirmModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  setIsShowing: PropTypes.func.isRequired,
  mail: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default Register;
