import { getUserCollects } from 'api/collection';
import Collection from 'components/Collection';
import Header from 'components/Header';
import SubHeader from 'components/SubHeader';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

const MyCollections = () => {
  const navigate = useNavigate();
  const [slideIndex, setSlideIndex] = useState(0);
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  const [perPage, setPerPage] = useState(5);
  const slides = [
    {
      title:
        "Un titre percutant aura plus d'impact. Laissez libre court à votre imagination .",
      src: '/assets/wisely_title.webp',
    },
    {
      title: 'Optez pour un titre qui résumera votre collecte .',
      src: '/assets/resume_title.webp',
    },
  ];

  const redirect = () => {
    const location = window.location.href;
    setTimeout(() => {
      navigate(`/se-connecter?redirect=${location}`);
    }, 500);
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    redirect();
  };

  const fetchCollections = () => {
    setLoading(true);
    getUserCollects(page, perPage)
      .then((res) => {
        setPagination(res.data.meta.pagination);
        const list = res?.data?.data.map((collection) => {
          return {
            id: collection.id,
            name: collection.title,
            categoryId: collection.category_id,
            amountGoal: collection?.montant_cagnotte,
            hasFixedAmount: collection?.montant_is_libre === 1,
            slug: collection?.slug,
            image: collection?.image,
            video:
              collection?.url_video === 'null' || collection?.url_video === null
                ? ''
                : collection?.url_video,
            publicCollectedAmount:
              res?.data?.data?.is_public_collect_amount === 1,
            is_active: collection?.is_active,
            publicAmountGoal: res?.data?.data?.montant_is_libre === 1,
          };
        });
        setCollections(_.uniqBy([...collections, ...list], 'id'));
        setLoading(false);
        if (res?.data.length === 0) {
          setMessage([true, "Vous n'avez pas encore de collecte"]);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          logOut();
        }
        setMessage([
          false,
          "La page n'a pas pu être chargée correctement, veuillez rafraîchir s'il vous plait",
        ]);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCollections();
  }, [page]);

  useEffect(() => {
    const range = [0, 1];
    const interval = setInterval(() => {
      setSlideIndex(Math.floor(Math.random() * range.length));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Mes collectes - KOUDPOUSS" />

      <Header />
      <section className="bg-white px-6 pb-20 pt-6">
        {/* Menus */}
        <SubHeader currentPage={2} />

        {/* My Collections */}
        {collections?.length > 0 && (
          <section className="space-y-4">
            {collections?.map((collection) => {
              return (
                <Collection
                  key={collection.id}
                  data={collection}
                  slideIndex={slideIndex}
                  slides={slides}
                />
              );
            })}
            {message.length > 0 && (
              <div
                className={`mb-4 text-center text-sm ${
                  message[0] ? 'text-primary' : 'text-red-500'
                }`}
              >
                {message[1]}
              </div>
            )}
          </section>
        )}

        {!loading && collections?.length === 0 && message.length === 0 && (
          <div className="mt-8 flex justify-center">
            <p className="text-center text-sm text-gray-500">
              Vous n&apos;avez pas encore de collecte
            </p>

            <Link
              to="/choisir-un-titre"
              className="ml-2 text-center text-sm text-primary"
            >
              Créer une collecte
            </Link>
          </div>
        )}

        {loading && (
          <div className="relative text-center">
            <div
              className={`mx-auto text-center transition-colors delay-300
                ${loading ? 'text-primary' : 'text-transparent'}`}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-live="polite"
                aria-busy="true"
                aria-labelledby="title-08a desc-08a"
                className="mx-auto h-16 w-16 md:h-24 md:w-24"
              >
                <title id="title-08a">loading</title>
                <path
                  d="M7 8H3V16H7V8Z"
                  className="animate animate-bounce fill-current "
                />
                <path
                  d="M14 8H10V16H14V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.2s]"
                />
                <path
                  d="M21 8H17V16H21V8Z"
                  className="animate animate-bounce fill-current [animation-delay:.4s]"
                />
              </svg>
            </div>
          </div>
        )}

        {/* load more */}
        {!loading && pagination.total !== collections.length && (
          <div className="mt-8 flex justify-center">
            <button
              type="button"
              className="rounded-full bg-primary px-4 py-2 text-sm font-semibold text-white"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Charger plus
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default MyCollections;
