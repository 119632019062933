/* eslint-disable import/no-unresolved */
/* eslint-disable */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Event from 'components/Event';
import React from 'react';
import { Helmet } from 'react-helmet';

import Banner from '../components/Banner';
import { useState } from 'react';
import { getTirelirePerEvent } from 'api/collection';
import { useEffect } from 'react';

const PopularCollection = () => {
  const Collections = [
    {
      image: 'solidarity.jpeg',
      name: 'Évènements de la vie',
      amount: '3750 €',
      categories: [
        {
          title: 'Solidarité',
          image: 'assets/solidarity.jpeg',
          imageDim: 'w-28 h-28',
          cardBackground: 'bg-slate-400/20 lg:bg-white',
          color: 'rgb(248, 176, 58)',
        },
        {
          title: 'Projet',
          image: 'assets/project.jpeg',
          imageDim: 'w-28 h-28',
          cardBackground: 'bg-slate-400/20 lg:bg-white/80',
          color: 'rgb(209, 192, 176)',
        },
        {
          title: 'Pot de départ',
          image: 'assets/farewell_party.jpg',
          imageDim: 'w-28 h-28',
          color: 'rgb(100, 71, 53)',
        },
        {
          title: 'Non classés',
          image: 'assets/unclassified.jpeg',
          imageDim: 'w-28 h-28',
          color: 'rgb(216, 230, 55)',
        },
      ],
    },
    {
      image: 'events.jpeg',
      name: 'Événements heureux',
      amount: '4000 €',
      categories: [
        {
          title: 'Mariage',
          image: 'assets/wedding.jpeg',
          imageDim: 'w-28 h-28',
          cardBackground: 'bg-slate-400/20 lg:bg-white',
          color: 'rgb(237, 109, 217)',
        },
        {
          title: 'Naissance',
          image: 'assets/birth.jpg',
          imageDim: 'w-28 h-28',
          cardBackground: 'bg-slate-400/20 lg:bg-white/80',
          color: 'rgb(154, 104, 175)',
        },
        {
          title: 'Anniversaire',
          image: 'assets/birthday.jpg',
          imageDim: 'w-28 h-28',
          color: 'rgb(216, 230, 55)',
        },
        {
          title: 'Évenementiel',
          image: 'assets/events.jpeg',
          imageDim: 'w-28 h-28',
          color: 'rgb(243, 70, 55)',
        },
      ],
    },
    {
      image: 'humanitaire.jpg',
      name: 'Humanitaire',
      amount: '5000 €',
      categories: [
        {
          title: 'Humanitaire',
          image: 'assets/humanitaire.jpg',
          imageDim: 'w-28 h-28',
          cardBackground: 'bg-slate-400/20 lg:bg-white/80',
          color: 'rgb(46, 110, 72)',
        },
      ],
    },
    {
      image: 'animal.jpg',
      name: 'Animalière',
      amount: '5000 €',
      categories: [
        {
          title: 'Animalière',
          image: 'assets/animal.jpg',
          imageDim: 'w-28 h-28',
          color: 'rgb(120, 180, 251)',
        },
      ],
    },
  ];

  const [eventsTirelire, setEventsTirelire] = useState({
    happyEvent: "0",
    lifeEvent: "0",
    humanityEvent: "0",
    animalyEvent: "0"
  })
  const [loading, setLoading] = useState(false)

  const fetchEventsData = async () => {
    setLoading(true)
    try {
      const [data1, data2, data3, data4] = await Promise.allSettled([getTirelirePerEvent("happy_event"), getTirelirePerEvent("life_event"), getTirelirePerEvent("humanity_event"), getTirelirePerEvent("animaly_event")])
      if (data1.value) {
        setEventsTirelire((prevValue) => ({...prevValue, happyEvent: data1.value.data?.total_tirelire}))
      }
      if (data2.value) {
        setEventsTirelire((prevValue) => ({...prevValue, lifeEvent: data2.value.data?.total_tirelire}))
      }
      if (data3.value) {
        setEventsTirelire((prevValue) => ({...prevValue, humanityEvent: data3.value.data?.total_tirelire}))
      }
      if (data4.value) {
        setEventsTirelire((prevValue) => ({...prevValue, animalyEvent: data4.value.data?.total_tirelire}))
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchEventsData()
  }, [])
  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Ma collecte populaire - KOUDPOUSS" />
      <Banner
        image="collete-populaire.webp"
        currentPage={3}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
      />
      <div className="w-full bg-white px-4 py-8 md:px-6 md:py-16 text-xl leading-8">
        <section className="w-full max-w-7xl mx-auto">
          <h1 className="mb-8 md:mb-16 text-center text-xl font-light text-black lg:text-2xl">
            Ta collecte est&nbsp;<span className="text-primary">populaire</span>
          </h1>
          <p className="mx-auto max-w-6xl text-lg md:text-xl lg:text-2xl">
            <span className="text-primary">La Tirelire Solidaire</span>
            &nbsp;récompense les porteurs de projet et c'est uniquement sur{' '}
            <span className="text-primary">Koud Pouss !</span> <br />
            C'est le coup d'pouce sympa, l'union des forces et la solidarité
            parce-qu'une plateforme qui permet de financer tous les projets ,
            c'est bien, mais une plateforme qui récompense les porteurs de
            projet , c'est mieux ! voir conditions
          </p>
          <h2 className="my-16 md:my-32 text-center text-xl font-light text-black lg:text-2xl">
            Découvrez le classement des{' '}
            <span className="text-primary">collectes. </span>
          </h2>
          <ul className="w-full">
            {Collections.map((Collection) => {
              return (
                <li className="relative w-full mb-4 md:mb-16">
                  <div className="flex flex-col gap-4 2xl:flex-row">
                    <figure
                      className="w-full h-48 md:h-80 bg-cover bg-center bg-no-repeat 2xl:h-[32rem] 2xl:w-1/2"
                      style={{
                        backgroundImage: `url(/assets/${Collection.image})`,
                      }}
                    />
                    <article className="w-full bg-white/20 p-4 md:p-6 lg:p-8 backdrop-blur-xl 2xl:absolute 2xl:left-1/3 2xl:max-w-3xl flex flex-col justify-between gap-6 md:gap-8">
                      <h3 className="text-xl md:text-2xl lg:text-3xl font-bold underline">
                        {Collection.name}
                      </h3>
                      <figure className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6">
                        <img
                          src="/assets/piggy_icon.png"
                          title="Collecte"
                          alt="Collecte"
                          className="w-10 h-10 md:w-12 md:h-12"
                        />
                        <figcaption className="flex-1 flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6 w-full">
                          <span className="text-lg md:text-xl lg:text-2xl font-bold">
                            {Collection.name === 'Évènements de la vie' ? `${eventsTirelire.lifeEvent} €` : Collection.name === 'Événements heureux' ? `${eventsTirelire.happyEvent} €` : Collection.name === 'Humanitaire' ? `${eventsTirelire.humanityEvent} €` : `${eventsTirelire.animalyEvent} €`}
                          </span>
                          <a href={`/classement/${Collection.name === 'Évènements de la vie' ? 'life_event' : Collection.name === 'Événements heureux' ? 'happy_event' : Collection.name === 'Humanitaire' ? 'humanity_event' : 'animaly_event'}`} className="w-full sm:w-auto">
                            <span className="block w-full text-center relative items-start justify-center rounded-full bg-gradient-to-b from-orange-100 to-primary px-4 md:px-6 lg:px-9 py-3 md:py-4 text-sm md:text-base font-bold leading-tight text-white hover:scale-105 transition-transform">
                              Voir le classement
                            </span>
                          </a>
                        </figcaption>
                      </figure>
                      <ul className="hidden md:flex flex-wrap justify-start gap-4 lg:gap-6">
                        {Collection.categories.map((event) => {
                          return (
                            <li
                              key={event?.title}
                              className="w-48 flex justify-center"
                            >
                              <Event data={event} />
                            </li>
                          );
                        })}
                      </ul>
                      <Splide
                        options={{
                          perPage: 1,
                          perMove: 1,
                          arrows: true,
                          pagination: false,
                          autoplay: false,
                          gap: 16,
                          rewind: false,
                          type: 'slide',
                          width: '100%'
                        }}
                        className="md:hidden custom-splide !p-0"
                      >
                        {Collection.categories.map((event) => (
                          <SplideSlide
                            key={event?.title}
                            className="flex justify-center"
                          >
                            <Event data={event} />
                          </SplideSlide>
                        ))}
                      </Splide>
                    </article>
                  </div>
                </li>
              );
            })}
          </ul>
        </section>
      </div>
    </div>
  );
};

export default PopularCollection;
