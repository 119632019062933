/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable */
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
/* eslint-disable unused-imports/no-unused-vars */
import 'video-react/dist/video-react.css'; // import css

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { getCategories } from 'api/category';
import {
  getActiveTirelire,
  getCollects,
  getTirelireAmount,
} from 'api/collection';
import Activity from 'components/Activity';
import ActivityWireFrame from 'components/ActivityWireframe';
import Banner from 'components/Banner';
import CountDownTimer from 'components/Countdown';
import Event from 'components/Event';
import Image from 'components/Image';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const Index = () => {
  const BASE_PATH = `/assets/`;
  const [categories, setCategories] = useState([]);
  const slides = [
    {
      title:
        'Envie de faire une surprise à un pote ? <br/>C\'est easy, connecte ta <span class="text-hl">Team !</span>',
      src: 'slideshow/sld1.webp',
      position: 'bottom',
    },
    {
      title:
        'Des milliers de volontaires engagés dans une cause humanitaire oeuvrent chaque jour. <br/>Vous êtes leur <span class="text-hl">unique soutien</span>.',
      src: 'slideshow/sld2.webp',
      position: 'bottom',
    },
    {
      title:
        'Soutenez la cause animale. Nos amis les animaux ont besoin <span class="text-hl">de vous</span>.',
      src: 'slideshow/sld3.webp',
      position: 'bottom',
    },
    {
      title:
        'Koud Pouss, sponsor officiel des événements <span class="text-hl">heureux</span>.',
      src: 'slideshow/sld4.webp',
      position: 'bottom',
    },
    {
      title:
        'Etudier à l\'étranger ? Donner vie à <span class="text-hl">ses projets</span> ? C\'est maintenant !',
      src: 'slideshow/sld5.webp',
      position: 'center',
    },
    {
      title:
        'On a tous une bonne raison pour commencer à <span class="text-hl">collecter</span>. Foncez!',
      src: 'slideshow/sld6.webp',
      position: 'bottom',
    },
  ];

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const services = [
    {
      image: '/assets/happiness.webp',
      title:
        "Koud Pouss, l'unique plateforme solidaire qui reverse une partie de ses contributions aux porteurs de projet !",
    },
    {
      image: '/assets/girl_left_parrot.webp',
      title:
        "Chaque évènement de la vie mérite d'être célébré. Koud Pouss oeuvre à vos côtés.",
    },
  ];
  const [slide, setSlide] = useState(services[0]);


  const mainCollectCategory = [
    {
      name: 'Humanitaire',
      categories: ['Humanitaire']
    },
    {
      name: 'Animali\u00e8re',
      categories: ['Animali\u00e8re']
    },
    {
      name: 'Evénementiel de la vie',
      categories: ['Projet', 'Non class\u00e9', 'Pot de d\u00e9part', 'Solidarit\u00e9']
    },
    {
      name: 'Evénementiel heureux', 
      categories: ['Naissance', 'Mariage', 'Anniversaire', '\u00c9v\u00e9nementiel']
    }
  ];
  const collectionsStatic = [
    {
      mainImage: 'children_humanity.webp',
      centerImage: 'consignee.jpeg',
      cornerImage: 'rations.jpeg',
      asideImage: 'united.jpeg',
      color: 'text-emerald-800',
      name: 'Humanitaire',
      amount: '7000',
      fans: '200 Supporters',
      likes: {
        image: 'thumbs_up.png',
        number: '150 votes',
      },
    },
    {
      mainImage: 'white_dog_happy.png',
      centerImage: 'veterinarian.jpeg',
      cornerImage: 'animals.jpeg',
      asideImage: 'animals_friends.jpeg',
      color: 'text-blue-500',
      name: 'Animalière',
      amount: '10000',
      fans: '200 Supporters',
      likes: {
        image: 'thumbs_up.png',
        number: '200 votes',
      },
    },
    {
      mainImage: 'nature_wood.jpeg',
      centerImage: 'nature_wood.jpeg',
      cornerImage: 'mountains.jpeg',
      asideImage: 'breads.jpeg',
      color: 'text-blue-500',
      name: 'Vie',
      amount: '10000',
      fans: '200 Supporters',
      likes: {
        image: 'thumbs_up.png',
        number: '200 votes',
      },
    },
    {
      mainImage: 'happy_friends.webp',
      centerImage: 'solidarity_club.jpeg',
      cornerImage: 'with_a_smile.jpeg',
      asideImage: 'hand_in_hand.jpeg',
      color: 'text-blue-500',
      name: 'Solidarité',
      amount: '10000',
      fans: '200 Supporters',
      likes: {
        image: 'thumbs_up.png',
        number: '200 votes',
      },
    },
    {
      mainImage: 'wedding.jpeg',
      centerImage: 'cinderella_shoe.jpeg',
      cornerImage: 'weeding_love.jpeg',
      asideImage: 'bridal_dress.jpeg',
      color: 'text-blue-500',
      name: 'Mariage',
      amount: '10000',
      fans: '200 Supporters',
      likes: {
        image: 'thumbs_up.png',
        number: '200 votes',
      },
    },
  ];
  const [collections, setCollections] = useState([]);

  const [collection, setCollection] = useState(collectionsStatic[0]);
  const [collectionDynamic, setCollectionDynamic] = useState({
    mainImage: '',
    centerImage: 'consignee.jpeg',
    cornerImage: '',
    asideImage: 'united.jpeg',
    color: '',
    name: '',
    amount: '0',
    fans: '0 Supporters',
    likes: {
      image: 'thumbs_up.png',
      number: '0 votes',
    },
    category: {
      title: '',
    },
  });

  const [pot, setPot] = useState(0);
  const [name, setName] = useState('');
  const [tirelire, setTirelire] = useState(null);
  const [tirelireValue, setTirelireValue] = useState('');
  const [currentCollectIndex, setCurrentCollectIndex] = useState(0);

  const fetchCategories = () => {
    getCategories().then((res) => {
      setCategories(
        res?.data?.data?.sort((a, b) => a.created_at > b.created_at)
      );
    });
  };

  const fetchCollections = () => {
    getCollects()
      .then((res) => {
        setActivities(
          res.data.data.map((act) => {
            return {
              id: act.id,
              categoryId: act?.category_id,
              subTitle: act?.title,
              image: act?.image,
              slug: act?.slug,
              target: act?.montant_cagnotte,
              video:
                act?.url_video === 'null' || act?.url_video === null
                  ? ''
                  : act?.url_video,
              publicCollectedAmount: act?.is_public_collect_amount === 1,
              publicAmountGoal: act?.montant_is_libre === 1,
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchTirelireData = async () => {
    try {
      const response = await getActiveTirelire();
      const tirelireData = response.data.data;
      setTirelire(tirelireData);
   
      if (tirelireData?.collects?.length > 0) {
        const formattedCollections = tirelireData.collects.map((item) => ({
          id: item.collect.data.id,
          mainImage: item.collect.data.image,
          centerImage: item.collect.data.image,
          slug: item.collect.data.slug,
          cornerImage: item.collect.data.image,
          asideImage: item.collect.data.image,
          color: item.category.data.color,
          name: item.collect.data.title,
          amount: item.collect.data.collected_amount.toString(),
          fans: `${Math.floor(Math.random() * 500)}`,
          likes: {
            image: 'thumbs_up.png',
            number: `${Math.floor(Math.random() * 300)} votes`,
          },
          category: {
            title: item.category.data.title,
          },
        }));
        console.log('Raw Tirelire Data:', tirelireData);
        console.log('Formatted Collections:', formattedCollections);
  
        setCollections(formattedCollections);

        if (formattedCollections.length > 0) {
          setCollectionDynamic(formattedCollections[0]);
        }
      }
    } catch (error) {
    }
  };

  const fetchTirelireTotal = () => {
    getTirelireAmount()
      .then((res) => {
        setTirelireValue(res.data.total_tirelire);
      })
      .catch((err) => {});
  };

  const preloadImages = (collections) => {
    collections.forEach(collection => {
      const img = new Image();
      img.src = collection.mainImage;
    });
  };

  console.log(collections);
  useEffect(() => {
    fetchCategories();
    fetchCollections();
    fetchTirelireData();
    fetchTirelireTotal();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide(services[Math.floor(Math.random() * services.length)]);
      setCollection(
        collectionsStatic[Math.floor(Math.random() * collectionsStatic.length)]
      );
      setPot(Math.floor(Math.random() * 2));
    }, 1000);

    return () => clearInterval(interval);
  }, [services, collectionsStatic]);

  useEffect(() => {
    const otherInterval = setInterval(() => {
      if (collections.length > 0) {
        setCurrentCollectIndex((prevIndex) => {
          const newIndex = prevIndex === collections.length - 1 ? 0 : prevIndex + 1;
          return newIndex;
        });
      }
    }, 5000);

    return () => {
      clearInterval(otherInterval);
    };
  }, [collections]);

  useEffect(() => {
    if (collections.length > 0 && currentCollectIndex < collections.length) {
      setCollectionDynamic(collections[currentCollectIndex]);
    }
  }, [currentCollectIndex, collections]);

  useEffect(() => {
    fetchTirelireData().then(() => {
      if (collections.length > 0) {
        preloadImages(collections);
      }
    });
  }, []);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Banner
        image={slides}
        currentPage={0}
        headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        bannerClass="h-banner-large"
        name={name}
        setName={setName}
      />
      <div className="bg-white text-center">
        <br className="hidden md:block" />
        <br className="hidden lg:block" />
        <p className="mx-auto w-fit px-4 text-xl md:text-2xl font-bold text-white highlight highlight-primary highlight-variant-1 ">
          0 Frais caché - 0 commission
        </p>
        <p className="mt-2 text-xs">
          Koud Pouss, sponsor officiel des causes qui vous sont chères
        </p>
        <h1
          id="categories"
          className="mx-auto px-3 py-4 md:py-7 text-center text-lg md:text-xl font-light text-black lg:text-2xl"
        >
          Une envie, une occasion, un événement à célébrer ? <br /> Koud Pouss
          est <span className="text-primary">La solution !</span>
        </h1>
        <ul className="hidden md:grid grid-cols-1  gap-4 px-4 sm:grid-cols-2 md:grid-cols-4 ">
          {categories?.map((event) => {
            return (
              <li key={`s_${event?.id}`} className="w-full flex justify-center [grid-column:auto]">
                <div className="w-full">
                  <Event data={event} name={name} />
                </div>
              </li>
            );
          })}
        </ul>
        <Splide
          options={{
            perPage: 3,
            perMove: 3,
            arrows: false,
            pagination: false,
            autoplay: false,
            rewind: false,
            type: 'slide',
            breakpoints: {
              320: {
                perPage: 1,
                perMove: 1,
                gap: 10,
              },
              640: {
                perPage: 1,
                perMove: 1,
                arrows: true,
                gap: 10,
              },
            },
          }}
          className="md:hidden custom-splide"
        >
          {categories?.map((event) => (
            <SplideSlide className='' key={`spc_${event?.id}`}>
              <Event data={event} name={name} />
            </SplideSlide>
          ))}
        </Splide>
        <h2 className="mx-auto mb-8 md:mb-16 mt-4 lg:mt-6 px-3 text-center text-xl md:text-2xl font-light text-black">
          Donnez vie à toutes vos envies et fédérez <br /> une communauté
          bienveillante autour de tous&nbsp;
          <span className="text-primary">vos projets !</span>
        </h2>
        <div
          className="bg-right relative flex items-end justify-center bg-no-repeat px-3 before:absolute before:h-full before:w-full before:bg-white lg:h-[19rem] lg:bg-cover lg:before:bg-transparent"
          style={{
            backgroundImage: `url(${BASE_PATH}montagne.7622a31d1e56afaab276.webp`,
          }}
        >
          <div className="relative z-10 mb-3 flex flex-col gap-8 lg:flex-row lg:gap-16">
            <figure className="h-full lg:w-1/2">
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full -translate-y-0 scale-100 lg:-translate-y-[1.2rem] lg:scale-125"
              />
            </figure>
            <aside className="card-gradient relative mx-auto max-w-md self-start rounded-3xl px-10 py-8 text-center text-xl text-white before:absolute before:left-[-20px] before:border-y-[10px] before:border-y-transparent before:border-r-yellow-500 md:px-24 md:bottom-8 lg:mx-0 lg:mt-10 before:lg:border-r-[30px]">
              <p>{slide.title}</p>
            </aside>
          </div>
        </div>
        <h2 className="text-center text-2xl text-primary  md:mt-10">
          La Tirelire Solidaire <br /> Le koud&apos;pouss qui boost !
        </h2>
        <section className="container mx-auto my-8 md:my-16 items-center px-4 md:px-8 lg:flex lg:px-16">
          <figure className="pet max-w-full safari before:content[''] before:bg-bottom relative z-[1] h-full min-h-full w-full rounded-b-lg before:absolute before:-bottom-48 before:-left-16 before:z-50 before:h-full before:w-72 before:bg-contain before:bg-no-repeat md:mx-auto">
            <div className="relative z-10 flex w-full items-center rounded-t-lg bg-gray-300 px-16 py-2">
              <div className="absolute left-4 top-2/4 flex -translate-y-1 space-x-1">
                <span className="h-2 w-2 rounded-full bg-red-600" />
                <span className="h-2 w-2 rounded-full bg-yellow-600" />
                <span className="h-2 w-2 rounded-full bg-green-600" />
              </div>
              <div className="flex h-full w-full items-center justify-center rounded-sm bg-gray-400 py-0.5 text-[.5rem] text-gray-800">
                www.koud-pouss.com
              </div>
            </div>
          
            <div className="flex min-h-[15rem] w-full relative rounded-b-lg bg-white">
              <div className="relative z-[100] mr-auto w-1/2 py-5">
                {pot === 0 && (
                  <div className="font-bold">
                    Ta collecte est <br />
                    <span className="text-primary text-xl">populaire ?</span>
                    <br />
                    <br />
                    Remporte la <br />
                    <span className="text-primary text-xl">Tirelire !</span>
                  </div>
                )}
                {pot === 1 && (
                  <div className="font-normal">
                    Ta collecte est
                    <span className="text-primary"> populaire ?</span>
                    <br />
                    <br />
                    Remporte la
                    <span className=" tex-primary"> Tirelire !</span>
                    <ul className="mt-4 inline-flex text-lg md:text-xl  lg:text-4xl font-extrabold">
                      {Array.from(tirelireValue?.split(''), (v, i) => (
                        <li
                          key={i}
                          className="count-up relative mr-0.5 flex items-center px-0.5 md:mr-1 md:px-1"
                        >
                          <div className="flex items-center bg-gradient-to-b from-[#FFEAFF] to-[#C55F80] bg-clip-text text-transparent">
                            {v}
                          </div>
                        </li>
                      ))}
                      <li className="count-up relative mr-0.5 flex items-center px-0.5 md:mr-1 md:px-1">
                        <div className="flex items-center bg-gradient-to-b from-[#FFEAFF] to-[#C55F80] bg-clip-text text-transparent">
                          €
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="relative w-1/2 overflow-hidden rounded-br-lg">
                <div className="flex items-center justify-center">
                  <img
                    className="scale-150"
                    src="/assets/ta-collecte-est-populaire.jpeg"
                    alt="La Tirelire"
                  />
                </div>
              </div>
            </div>
          </figure>
          <article className="mt-24 lg:mt-0 xl:ml-6 xl:max-w-md">
            <p className="my-5 text-lg">
              Invitez vos amis à voter pour votre collecte afin de remporter la
              Tirelire Solidaire. Koud Pouss est Solidaire des causes qui vous
              tiennent à cœur. <br />
              <a href="/comment-ca-marche" className="text-yellow-500">
                En savoir plus
              </a>
            </p>

            <CountDownTimer
              date={
                tirelire
                  ? dayjs(tirelire.last_date).format()
                  : dayjs().endOf('month').add(1, 'second').format()
              }
            />
          </article>
        </section>
        {tirelire && collections.length > 0 && (
          <>
            <h2 className="mx-auto px-3 pb-8 md:pb-16 pt-4 md:pt-8 text-center text-xl md:text-2xl font-light text-black">
              <p className="text-md mt-2">
                Découvrez les collectes les plus <span className="text-primary">populaires</span> et votez pour votre préférée !
              </p>
            </h2>
            <section className="relative mx-auto mb-8 md:mb-16 flex justify-center px-3 lg:container md:px-8">
              <figure className="relative hidden md:block md:w-full">
                <div
                  className="aspect-square bg-center ml-24 mr-10 flex h-full scale-100 items-end justify-end rounded-xl bg-cover bg-no-repeat"
                  style={{
                    backgroundImage: `url(${BASE_PATH}${collection.centerImage}`,
                  }}
                >
                  <figure className="-mb-4 rounded-l-full bg-white py-5 pl-5 text-right">
                    <img
                      src="/assets/thumbs_up.png"
                      alt="Aime"
                      title="Aime"
                      className="mx-auto"
                    />
                    <span className="font-bold">{collection.likes.number}</span>
                  </figure>
                </div>
                <div
                  className="absolute top-0 h-20 w-32 scale-110 rounded-lg bg-cover bg-no-repeat"
                  style={{
                    backgroundImage: `url(${BASE_PATH}${collection.cornerImage}`,
                  }}
                />
                <div
                  className="absolute -bottom-4 left-0 h-20 w-20 scale-110 rounded-lg bg-cover bg-no-repeat"
                  style={{
                    backgroundImage: `url(/assets/ta-collecte-est-populaire.jpeg)`,
                  }}
                />
              </figure>
              <article className="inline-block w-[320px] md:w-[400px] text-sm md:block transform transition-all duration-500 hover:scale-105">
                <Link to={`/collecte/${collectionDynamic.slug}`} className="block">
                  <div className="bg-white rounded-lg shadow-lg p-4 h-full">
                    <div className="mb-4">
                      <h2 className="text-xl font-bold truncate mb-2">
                        {collectionDynamic.name}
                      </h2>
                      <div 
                        className="w-full rounded-full px-3 py-1.5 text-center"
                        style={{
                          backgroundColor: `${collectionDynamic.color}15`,
                        }}
                      >
                        <span 
                          className="font-medium text-sm"
                          style={{ color: collectionDynamic.color }}
                        >
                          Top collecte de la catégorie {mainCollectCategory.find(cat => 
                            cat.categories.includes(collectionDynamic.category.title)
                          )?.name || collectionDynamic.category.title}
                        </span>
                      </div>
                    </div>

                    <figure className="relative w-full h-[250px]">
                      <img
                        src={`${collectionDynamic.mainImage}`}
                        alt=""
                        title={collectionDynamic.name}
                        className="h-full w-full object-cover rounded-lg"
                        style={{ aspectRatio: '4/3' }}
                      />
                      <div className="absolute bottom-3 right-3 bg-white rounded-full p-2 shadow-md">
                        <div className="flex items-center">
                          <img
                            src="/assets/thumbs_up.png"
                            alt="Votes"
                            className="w-5 h-5 mr-1"
                          />
                          <span className="font-bold text-sm">{collectionDynamic.likes.number}</span>
                        </div>
                      </div>
                    </figure>

                    <div className="mt-4 flex items-center justify-between bg-gray-50 rounded-lg p-4">
                      <div>
                        <span className="text-gray-600 text-sm">Collectés</span>
                        <div className="font-bold text-primary text-xl">{collectionDynamic.amount} €</div>
                      </div>
                      <div>
                        <span className="text-gray-600 text-sm">Supporters</span>
                        <div className="font-bold text-md">{collectionDynamic.fans}</div>
                      </div>
                    </div>

                    <Link
                      to="/ma-collecte-populaire"
                      className="mt-4 w-full rounded-full py-3 text-center text-white hover:opacity-90 transition-colors flex items-center justify-center gap-2"
                      style={{ backgroundColor: collectionDynamic.color }}
                    >
                      <span>Voir plus</span>
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </Link>
                  </div>
                </Link>
              </article>
            </section>
          </>
        )}
        <h2 className="mx-auto px-3 pb-6 md:pb-10 text-center text-xl md:text-2xl font-normal text-black">
          Une <span className="text-primary">collecte</span> pour toutes les
          causes et occasions qui vous <br className="hidden md:block" />
          <span className="text-blue-600">ressemble</span> et{' '}
          <span className="text-green-600">nous rassemble !</span>
        </h2>

        <div className="overflow-hidden">
          <figure className="relative mb-14 bg-primary pl-5 after:absolute after:-bottom-5 after:left-0 after:border-r-[70vw] after:border-t-[20px] after:border-r-transparent after:border-t-orange-400">
            <section
              className="bg-center h-72 w-full lg:h-96 bg-cover bg-no-repeat md:py-72"
              style={{
                backgroundImage: `url(${BASE_PATH}children-1822704_1920.jpg`,
              }}
            />
            <figcaption className="absolute left-0 top-24 w-full">
              <h2 className="mx-auto max-w-7xl px-6 text-center text-lg font-bold text-white lg:text-3xl">
                Faire appel à la solidarité et fédérer une team motivée <br />
                autour de votre projet n'aura jamais été aussi <br />
                <span className="text-primary">facile.</span>
              </h2>
            </figcaption>
          </figure>
        </div>
        <h2 className="my-6 md:my-10 px-3 text-center text-xl md:text-2xl">
          Ils ont rejoint <span className="text-primary">Koud Pouss</span>
          <br />
          Et ils ont eu raison
        </h2>
        <Splide
          className="custom-splide"
          options={{
            perPage: 1,
            perMove: 1,
            arrows: true,
            pagination: false,
            autoplay: false,
            gap: 34,
            rewind: false,
            type: 'slide',
            
            breakpoints: {
              640: {
                perPage: 1,
                perMove: 1,
                gap: 10,
              },
              980: {
                perPage: 2,
                perMove: 2,
                gap: 10,
              },
              1024: {
                perPage: 3,
                perMove: 3,
                gap: 10,
              },
              1280: {
                perPage: 4,
                perMove: 4,
                gap: 10,
              },
            },
          }}
        >
          {activities.map((activity) => (
            <SplideSlide
              key={`spc_${activity?.id}`}
              className="w-full max-w-xs"
            >
              <Activity data={activity} />
            </SplideSlide>
          ))}

          {activities?.length === 0 &&
            loading &&
            [0, 1, 2, 3].map((i) => (
              <SplideSlide key={`spc_${i}`} className="w-full max-w-xs">
                <ActivityWireFrame />
              </SplideSlide>
            ))}
        </Splide>
        <div className="mb-8 flex justify-center">
          <a
            className="rounded-full mt-4 bg-primary px-4 py-2 text-sm font-semibold text-white"
            href="/collectes"
          >
            Voir toutes les collectes
          </a>
        </div>
        <ul className="container mx-auto grid grid-cols-1 gap-8 px-6 md:px-8 lg:grid-cols-2 lg:px-24">
          <li>
            <article className="flex h-full flex-col rounded-3xl bg-yellow-500/90 px-10 py-8 text-center text-xl text-white">
              <p className="flex-1">
                Koud Pouss, la plateforme qui encourage les initiatives
                solidaires et innove la collecte en ligne. Collecter de chez soi
                devient plus simple et plus sympa avec Koud Pouss.
              </p>

              <div className="mt-auto flex flex-col items-center space-y-4">
                <div className="inline-flex rounded-2xl border-b-4 border-black bg-yellow-500/90 px-8 py-5 text-white">
                  {[1, 2, 3, 4, 5].map((index) => (
                    <div
                      key={index}
                      className="relative mx-1 flex h-10 w-8 items-center justify-center bg-black"
                    >
                      {index}
                    </div>
                  ))}
                </div>
                <p className="font-bold">€ cagnottes</p>
              </div>
            </article>
          </li>

          <li>
            <article className="flex h-full flex-col rounded-3xl bg-yellow-500/90 px-10 py-8 text-center text-xl text-white">
              <p className="flex-1">
                L&apos;union des forces dans un but commun. Koud Pouss vous
                accompagne afin de vous aider à réaliser vos objectifs.
              </p>

              <div className="mt-auto flex flex-col items-center space-y-4">
                <div className="inline-flex rounded-2xl border-b-4 border-black bg-yellow-500/90 px-8 py-5 text-white">
                  {[1, 2, 3, 4, 5].map((index) => (
                    <div
                      key={index}
                      className="relative mx-1 flex h-10 w-8 items-center justify-center bg-black"
                    >
                      {index}
                    </div>
                  ))}
                </div>
                <p className="font-bold">Causes soutenues</p>
              </div>
            </article>
          </li>
        </ul>
        <h2 className="mb-6 px-3 text-center text-2xl font-light md:mb-16">
          Notre priorité :
          <span className="mb-2 mt-4 block">
            La solidarité pour des porteurs de projets satisfaits.
          </span>
          Connectez votre team et donnez vie à vos{' '}
          <span className="mt-2 text-primary">projets !</span>
        </h2>
        <ul className="container mx-auto text-center text-xl font-bold md:flex md:px-8 lg:px-24">
          <li className="mb-6 md:w-1/3">
            <figure>
              <Image
                src="/assets/secure.png"
                loader="/assets/secure-tiny.png"
                alt="SSL"
                title="Plateforme de Dons sécurisés"
                className="mx-auto h-40"
              />
              <figcaption className="mt-5">
                Plateforme de <br /> Dons sécurisés
              </figcaption>
            </figure>
          </li>
          <li className="mb-6 md:w-1/3">
            <figure>
              <Image
                src="/assets/account.png"
                loader="/assets/account-tiny.png"
                alt="Smartphones - tablettes"
                title="Comptabilité Smartphones - tablettes"
                className="mx-auto h-40"
              />
              <figcaption className="mt-5">
                Comptabilité <br /> Smartphones & tablettes
              </figcaption>
            </figure>
          </li>
          <li className="mb-6 md:w-1/3">
            <figure>
              <Image
                src="/assets/fly.png"
                loader="/assets/fly-tiny.png"
                alt="Fusée"
                title="Booster votre collecte Grace à nos services"
                className="mx-auto h-40"
              />
              <figcaption className="mt-5">
                Booster votre collecte <br /> Grace à nos services
              </figcaption>
            </figure>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Index;
