/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable */
import 'react-image-crop/dist/ReactCrop.css';

import { getCategories } from 'api/category';
import { createCollect, verifySlug } from 'api/collection';
import Banner from 'components/Banner';
import Header from 'components/Header';
import IconDownload from 'components/icons/IconDownload';
import InternalEditor from 'components/InternalEditor';
import { fr } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Helmet } from 'react-helmet';
import ReactCrop from 'react-image-crop';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router-dom';
import useOutsideClick from 'utils/useOutsideClick';
import * as Yup from 'yup';

const NewCollectionSchema = Yup.object().shape({
  name: Yup.string()
    .required('Veuillez entrer un nom pour la collecte')
    .min(5, 'Le titre de la collecte doit contenir au moins 5 caractères')
    .max(50, 'Le titre de la collecte ne doit pas dépasser 50 caractères'),
});

const validateVideoLink = (value) => {
  let error = false;
  try {
    Boolean(new URL(value));
  } catch (e) {
    error = true;
  }
  return error;
};

const NewCollection = () => {
  const formRef = useRef(null);
  const location = useLocation();
  const category = location.pathname?.split('/')[3]
    ? decodeURI(location.pathname?.split('/')[3])
    : '';
  const [endingDate, setEndingDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const name = location.pathname?.split('/')[5]
    ? decodeURI(location.pathname?.split('/')[5])
    : '';
  const navigate = useNavigate();
  const [image, setImage] = useState('');
  const [resizedImage, setResizedImage] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    x: 10,
    y: 10,
    width: 80,
    height: 80,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const htmlString =
    "\n\n\nIl n'y a pas de petit don. Chaque euro compte et peut avoir un gros impact si nous sommes nombreux à participer. Pensez à tous ces petits gestes qui, mis bout à bout, peuvent changer les choses. Pensez à partager cette collecte autour de vous ! Ensemble On Est Plus Forts.";

  const initialContent = {
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: 'normal',
              style: '',
              text: '',
              type: 'text',
              version: 1,
            },
          ],
          direction: 'ltr',
          format: '',
          indent: 0,
          type: 'paragraph',
          version: 1,
        },
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: 'normal',
              style: '',
              text: '',
              type: 'text',
              version: 1,
            },
          ],
          direction: 'ltr',
          format: '',
          indent: 0,
          type: 'paragraph',
          version: 1,
        },
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: 'normal',
              style: '',
              text: htmlString,
              type: 'text',
              version: 1,
            },
          ],
          direction: 'ltr',
          format: '',
          indent: 0,
          type: 'paragraph',
          version: 1,
        },
      ],
      direction: 'ltr',
      format: '',
      indent: 0,
      type: 'root',
      version: 1,
    },
  };

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [fixedAmountToCollect, setFixedAmountToCollect] = useState(true);
  const [addEndingDate, setAddEndingDate] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [isAmountPublic, setIsAmountPublic] = useState(true);
  const [addVideo, setAddVideo] = useState(false);
  const [message, setMessage] = useState([]);
  const [cat, setCat] = useState(null);
  const stepsSharing = [
    {
      banner: '/fiance.webp',
      position: 'bg-top',
      title: 'Choisissez un titre pour votre collecte',
      slides: [
        {
          title:
            "Un titre percutant aura plus d'impact. Laissez libre court à votre imagination .",
          src: '/assets/wisely_title.png',
        },
        {
          title: 'Optez pour un titre qui résumera votre collecte .',
          src: '/assets/resume_title.jpeg',
        },
      ],
    },
    {
      banner: '/happy_family.webp',
      position: 'bg-center',
      title: (
        <>
          Choisissez une image ou une vidéo
          <br /> pour représenter votre collecte
        </>
      ),
      slides: [
        {
          title: 'Votre média doit avoir une bonne résolution .',
          src: '/assets/media_quality.png',
        },
        {
          title:
            'Choissisez une image ou une vidéo pour représenter votre collecte .',
          src: '/assets/represent_collection.png',
        },
      ],
    },
    {
      banner: '/happy_friends.webp',
      position: 'bg-center',
      title: "Visualisez l'aperçu de votre média",
      slides: [
        {
          title: 'Recadrez vos images en quelques secondes .',
          src: '/assets/resize_media.jpeg',
        },
        {
          title:
            'Votre média est visible dès la diffusion de votre collecte sur les réseaux sociaux .',
          src: '/assets/media_visibility.png',
        },
      ],
    },
    {
      banner: '/pet_urgence.webp',
      position: 'bg-top-4',
      title: 'Personnalisez le lien de votre collecte',
      slides: [
        {
          title: 'Une fois crée, votre lien ne pourra plus être modifié .',
          src: '/assets/no_return.jpg',
        },
        {
          title: 'Ajoutez un chiffre, si votre lien est déjà utilisé .',
          src: '/assets/add_a_number.png',
        },
      ],
    },
    {
      banner: '/parametrage.webp',
      position: 'bg-center',
      title: 'Définissez la somme à collecter',
      slides: [
        {
          title: 'Définissez un montant libre ou un montant à collecter .',
          src: '/assets/amount_to_collect.png',
        },
        {
          title:
            'Un clic suffit pour modifier indéfiniment vos paramètres de visibilité .',
          src: '/assets/warning_before_clicking.png',
        },
      ],
    },
    {
      banner: '/decrivez_votre_collecte.webp',
      position: 'bg-center',
      title: 'Décrivez votre collecte',
      slides: [
        {
          title:
            'Une bonne description est essentiel au succès de votre collecte .',
          src: '/assets/need_description.jpeg',
        },
        {
          title: 'Exprimez librement votre initiative .',
          src: '/assets/speak_freely.jpeg',
        },
      ],
    },
    {
      banner: '/bonne_collecte.webp',
      position: 'bg-center',
      title: 'Félicitations, votre collecte est prête!',
      slides: [
        {
          title:
            'Koud Pouss, partenaire officiel, des causes qui vous sont chères.',
          src: '/assets/slogan.png',
        },
   
      ],
    },
  ];
  const [slide, setSlide] = useState(0);

  const canDefine = (e, which) => {
    if (which === 'amountToCollect') {
      setFixedAmountToCollect(e.target.checked);
    } else {
      setAddEndingDate(e.target.checked);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // S'assurer que le slide existe dans le tableau actuel
      const currentSlides = stepsSharing[step - 1]?.slides || [];
      const maxIndex = currentSlides.length - 1;
      setSlide(current => current >= maxIndex ? 0 : current + 1);
    }, 2000);

    return () => clearInterval(interval);
  }, [step, stepsSharing]);

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
      setSlide(0); // Réinitialiser le slide à 0 lors du retour
    }
  };

  const openCalendar = () => {
    setShowCalendar(true);
  };

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const calendarDateRef = useOutsideClick(closeCalendar);

  const fetchCategories = () => {
    getCategories().then((res) => {
      setCategories(res.data.data?.sort((a, b) => a.created_at > b.created_at));
    });
  };

  const redirect = () => {
    const newLocation = window.location.href;
    setTimeout(() => {
      navigate(`/se-connecter?redirect=${newLocation}`);
    }, 500);
  };

  useEffect(() => {
    if (categories.length > 0) {
      const selected = categories.find(
        (c) => c.title?.toLowerCase() === category?.toLowerCase()
      );
      setCat(selected);
    }
  }, [categories]);

  useEffect(() => {
    fetchCategories();

    if (!localStorage.getItem('persist:root')) {
      redirect();
    }

    const state = localStorage.getItem('xstate');
    if (
      state &&
      typeof JSON?.parse(state) === 'object' &&
      !Array.isArray(state)
    ) {
      const parsedState = JSON.parse(state);

      formRef.current.setValues(parsedState);
    }
  }, []);

  const getCroppedImg = (sourceImage, cr, filename) => {
    if (!sourceImage) {
      console.error('Source image is null or undefined');
      return null;
    }

    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cr.width;
    canvas.height = cr.height;
    const ctx = canvas.getContext('2d');

    try {
      ctx.drawImage(
        sourceImage,
        cr.x * scaleX,
        cr.y * scaleY,
        cr.width * scaleX,
        cr.height * scaleY,
        0,
        0,
        cr.width,
        cr.height
      );

      return new Promise((resolve) => {
        canvas.toBlob((file) => {
          if (file) {
            setResizedImage(file);
            resolve(URL.createObjectURL(file));
          } else {
            console.error('Failed to create blob from canvas');
            resolve(null);
          }
        }, 'image/jpeg');
      });
    } catch (error) {
      console.error('Error cropping image:', error);
      return Promise.resolve(null);
    }
  };

  const makeClientCrop = async (cropp) => {
    if (imageData && cropp.width && cropp.height) {
      try {
        const croppedImg = await getCroppedImg(
          imageData,
          cropp,
          'cropped-image.jpg'
        );
        if (croppedImg) {
          setCroppedImageUrl(croppedImg);
        }
      } catch (error) {
        console.error('Error making client crop:', error);
      }
    }
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    redirect();
  };

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Choisir un titre à votre collecte - KOUDPOUSS" />
      {stepsSharing[step - 1]?.banner ? (
        <Banner
          image={stepsSharing[step - 1].banner}
          bgPosition={stepsSharing[step - 1].position}
          currentPage={1}
          headerMessageContainer="items-end xl:ml-auto xl:mr-0 lg:text-left"
        />
      ) : (
        <Header />
      )}
      <section className="bg-white px-4 pt-10 md:px-10">
        <div className="lg:flex">
          <div className="mb-16 grow">
            <h1
              className={`bg-white text-center text-2xl text-gray-400/50 ${
                step !== 6 && 'mb-4'
              }`}
            >
              {stepsSharing[step - 1].title}
            </h1>
            {step === 3 && (
              <>
                {image && !addVideo && (
                  <p className="mb-6 text-center text-red-500">
                    Vous pouvez recadrer votre image
                  </p>
                )}

                {!image && !addVideo && (
                  <img
                    alt="default"
                    className="mx-auto max-w-md"
                    src={cat?.defaultimage}
                  />
                )}

                {image && !formRef.current?.values?.videoLink && !addVideo && (
                  <div className="mx-auto max-w-md">
                    <ReactCrop
                      src={image}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={(img) => {
                        if (img) {
                          setImageData(img);
                        }
                      }}
                      onComplete={(cropped) => {
                        if (image && cropped) {
                          makeClientCrop(cropped);
                        }
                      }}
                      onChange={(cropData) => setCrop(cropData)}
                    />

                    {croppedImageUrl && (
                      <img
                        alt="Crop"
                        style={{ maxWidth: '100%' }}
                        src={croppedImageUrl}
                      />
                    )}
                  </div>
                )}

                {!image && formRef.current?.values?.videoLink && addVideo && (
                  <div className="mx-auto max-w-md">
                    <ReactPlayer
                      width="100%"
                      height={300}
                      url={formRef.current?.values?.videoLink}
                    />
                  </div>
                )}
              </>
            )}
            <Formik
              innerRef={formRef}
              initialValues={{
                name: name || '',
                file: '',
                endingDate: '',
                videoLink: '',
                linkName: '',
                hasEndingDate: false,
                hasVideo: false,
                hasAmountToCollect: true,
                amountToCollect: 0,
                isPublic: true,
                isAmountPublic: true,
                description: '',
              }}
              validationSchema={NewCollectionSchema}
              onSubmit={(values, { setErrors, resetForm, setFieldValue }) => {
                if (!loading) {
                  const errors = {};
                  const formData = new FormData();

                  formData.append('title', values.name);
                  formData.append('category_id', cat?.id);
                  formData.append('slug', values.linkName);
                  formData.append('url_video', values.videoLink);
                  formData.append('is_active', values.isPublic ? 1 : 0);
                  formData.append(
                    'is_public_collect_amount',
                    values.isAmountPublic ? 1 : 0
                  );
                  // blob to File
                  const newFile = new File([resizedImage], values.file.name, {
                    type: values.file.type,
                  });

                  if (values.file !== '') {
                    if (resizedImage) formData.append('collecteimg', newFile);
                    else formData.append('collecteimg', values.file);
                  }

                  switch (step) {
                    case 2:
                      if (
                        (values.videoLink !== '' &&
                          validateVideoLink(values.videoLink)) ||
                        (values.videoLink === '' && addVideo)
                      ) {
                        setErrors({
                          ...errors,
                          videoLink: 'Veuillez saisir un lien vidéo valide',
                        });
                        return;
                      }
                      if (
                        values.videoLink !== '' &&
                        !validateVideoLink(values.videoLink)
                      ) {
                        formData.append('url_video', values.videoLink);
                        setFieldValue('file', '');
                        setImage('');
                      }
                      setStep(3);
                      break;
                    case 4:
                      setLoading(true);
                      if (values.linkName) {
                        verifySlug({
                          slug: values.linkName,
                        })
                          .then(() => {
                            setLoading(false);
                            setStep(5);
                          })
                          .catch((err) => {
                            if (err.response.status === 403) {
                              localStorage.setItem(
                                'xstate',
                                JSON.stringify({
                                  ...formRef.current.values,
                                  fileURL: croppedImageUrl,
                                })
                              );
                              setTimeout(() => {
                                logOut();
                              }, 300);
                            } else if (err.response.status === 400) {
                              setLoading(false);
                              setErrors({
                                ...errors,
                                linkName:
                                  'Le lien que vous avez choisi est déjà utilisé. Vous pouvez toujours ajouter un chiffre ou un caractère spécial à la fin de votre lien',
                              });
                            } else {
                              setLoading(false);
                              setErrors({
                                ...errors,
                                linkName:
                                  'Une erreur est survenue. Veuillez réessayer',
                              });
                            }
                          });
                      } else {
                        setLoading(false);
                        setErrors({
                          ...errors,
                          linkName: 'Veuillez saisir un lien',
                        });
                      }
                      break;
                    case 5:
                      if (
                        !values.hasAmountToCollect &&
                        values.amountToCollect < 20
                      ) {
                        setErrors({
                          ...errors,
                          amountToCollect:
                            'Le montant collecté doit être supérieure à 20€',
                        });
                        break;
                      } else if (
                        !values.hasAmountToCollect &&
                        values.amountToCollect > 0
                      ) {
                        formData.append(
                          'montant_cagnotte',
                          values.amountToCollect || 0
                        );
                      }
                      if (
                        values.hasEndingDate &&
                        values.endingDate.length === 0
                      ) {
                        setErrors({
                          ...errors,
                          endingDate: 'Veuillez précisez une date',
                        });
                        break;
                      }
                      setStep(6);
                      break;
                    case 6:
                      setStep(7);
                      break;
                    case 7:
                      setLoading(true);
                      formData.append(
                        'montant_is_libre',
                        values.hasAmountToCollect ? 1 : 0
                      );
                      if (
                        !values.hasAmountToCollect &&
                        values.amountToCollect > 0
                      ) {
                        formData.append(
                          'montant_cagnotte',
                          values.amountToCollect
                        );
                      }
                      if (values.endingDate) {
                        formData.append('end_date', values.endingDate);
                      }
                      if (values.description) {
                        formData.append(
                          'description',
                          JSON.stringify(values.description, null, 4)
                        );
                      }
                      createCollect(formData)
                        .then(() => {
                          setLoading(false);
                          setMessage([true, 'Votre collecte a été créée']);

                          localStorage.removeItem('xstate');
                          setTimeout(() => {
                            navigate('/mes-collectes');
                          }, 2500);
                        })
                        .catch((err) => {
                          if (err.response.status === 403) {
                            localStorage.setItem(
                              'xstate',
                              JSON.stringify({
                                ...formRef.current.values,
                                fileURL: croppedImageUrl,
                              })
                            );
                            setTimeout(() => {
                              logOut();
                            }, 300);
                          } else {
                            setLoading(false);
                            setMessage([
                              false,
                              'Une erreur est survenue, veuillez réessayer',
                            ]);
                          }
                        });
                      break;
                    default:
                      setStep(step + 1);
                      break;
                  }
                }
              }}
              classNam="w-full"
            >
              {({ errors, touched, setFieldValue }) => (
                <Form className="flex w-full flex-col space-y-4">
                  {step === 1 && (
                    <>
                      <Field
                        id="name"
                        name="name"
                        type="text"
                        maxLength={50}
                        placeholder="C'est parti !"
                        className="relative mx-auto w-full max-w-xl items-start justify-center rounded-full border-2 border-primary p-6 text-base leading-tight"
                      />
                      <div className="mt-2 text-center text-sm text-gray-500">
                        Maximum 50 caractères
                      </div>
                      {errors.name && touched.name ? (
                        <div className="text-center text-sm text-red-400">
                          {errors.name}
                        </div>
                      ) : null}
                    </>
                  )}
                  {step === 2 && (
                    <div className="flex flex-col items-center">
                      <p className="block w-full grow py-4 text-center">
                        Continuez si vous souhaitez ajouter votre média
                        ultérieurement
                      </p>
                      <figure className="items-center md:flex">
                        {image && (
                          <img
                            src={image}
                            className="aspect-h-auto aspect-w-auto mx-auto mb-4 block w-1/3 md:mb-0
                           md:mr-3 md:w-20"
                            alt="thumbnail"
                          />
                        )}
                        <label
                          htmlFor="file"
                          className={`flex cursor-pointer items-center rounded-md border border-primary px-6 py-5 text-xs font-bold text-primary hover:opacity-75
                          ${addVideo ? 'bg-gray-100' : 'bg-white'}`}
                        >
                          <IconDownload />
                          &nbsp;TÉLÉCHARGEZ L&#39;IMAGE DE VOTRE COLLECTE
                          <input
                            name="image"
                            type="file"
                            id="file"
                            disabled={addVideo}
                            accept="image/png, image/jpeg, image/jpg, image/webp"
                            onChange={(event) => {
                              const file = event.currentTarget.files?.[0];
                              if (file) {
                                const imageUrl = URL.createObjectURL(file);
                                setImage(imageUrl);
                                setFieldValue('file', file);
                                setFieldValue('videoLink', '');
                              }
                            }}
                            className="hidden"
                          />
                        </label>
                      </figure>
                      <span className="my-6 block text-center">Ou</span>
                      <div className="my-5 flex items-start">
                        <span className="mr-2 block w-full grow">
                          Utiliser une vidéo à la place
                        </span>
                        <div>
                          <label
                            htmlFor="video_a_utiliser"
                            className="relative inline-flex cursor-pointer items-center"
                            aria-label="Utiliser une vidéo à la place"
                          >
                            <input
                              type="checkbox"
                              checked={addVideo}
                              onChange={(e) => {
                                setFieldValue('hasVideo', e.target.checked);
                                setAddVideo(e.target.checked);
                                if (!e.target.checked) {
                                  setFieldValue('file', '');
                                  setImage('');
                                }
                              }}
                              id="video_a_utiliser"
                              className="peer sr-only"
                            />
                            <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                          </label>
                        </div>
                      </div>
                      <Field
                        name="videoLink"
                        type="text"
                        disabled={!addVideo}
                        placeholder="Insérer le lien de votre vidéo"
                        className="relative mx-auto w-full max-w-xl items-start justify-center rounded-full border-2 border-primary p-6 text-base leading-tight"
                      />
                      {errors.videoLink && touched.videoLink ? (
                        <div className="text-sm text-red-400">
                          {errors.videoLink}
                        </div>
                      ) : null}
                    </div>
                  )}
                  {step === 4 && (
                    <>
                      <div className="relative mx-auto flex w-full max-w-lg flex-col items-center justify-center rounded-full border-2 border-primary p-4 text-base leading-tight sm:flex-row md:p-6">
                        <span className="mb-2 shrink-0 sm:mb-0 sm:mr-2">
                          koud-pouss.com/
                        </span>
                        <Field
                          name="linkName"
                          type="text"
                          className="w-full grow bg-transparent text-center sm:w-auto sm:text-left"
                        />
                      </div>
                      {errors.linkName && touched.linkName ? (
                        <div className="mx-auto max-w-lg text-sm text-red-400">
                          {errors.linkName}
                        </div>
                      ) : null}
                    </>
                  )}
                  {step === 5 && (
                    <div className="text-center text-2xl text-gray-400/50">
                      <div className="mx-auto w-full max-w-lg">
                        <div className="my-5 flex items-center">
                          <span className="block w-full grow">
                            Montant libre
                          </span>
                          <label
                            htmlFor="fixer_le_montant"
                            className="relative inline-flex cursor-pointer items-center"
                            aria-label="Montant libre"
                          >
                            <input
                              type="checkbox"
                              checked={fixedAmountToCollect}
                              onChange={(e) => {
                                setFieldValue(
                                  'hasAmountToCollect',
                                  e.target.checked
                                );
                                canDefine(e, 'amountToCollect');
                              }}
                              id="fixer_le_montant"
                              className="peer sr-only"
                            />
                            <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                          </label>
                        </div>
                      </div>
                      {!fixedAmountToCollect && (
                        <div className="mx-auto w-full max-w-lg">
                          <div className="my-5">
                            <span className="block w-full grow text-sm font-medium text-gray-900">
                              Fixer le montant
                            </span>
                          </div>
                          <div>
                            <div className="relative">
                              <Field
                                name="amountToCollect"
                                type="number"
                                min="0"
                                className="relative mx-auto w-full items-start justify-center rounded-full border-2 border-primary p-6 text-base leading-tight"
                              />
                              <span className="absolute right-5 top-5 text-gray-400/50">
                                €
                              </span>
                            </div>
                            {errors.amountToCollect &&
                            touched.amountToCollect ? (
                              <div className="text-sm text-red-400">
                                {errors.amountToCollect}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                      <h2 className="my-10 text-center text-2xl text-gray-400/50">
                        Déterminez la visibilité
                      </h2>
                      <div className="flex flex-wrap items-center justify-center">
                        <div className="mr-5">
                          <span className="my-3 block">De votre collecte</span>
                          <label
                            htmlFor="de-votre-collecte"
                            className="relative inline-flex cursor-pointer items-center"
                            aria-label="De votre collecte"
                          >
                            <input
                              type="checkbox"
                              checked={isPublic}
                              id="de-votre-collecte"
                              className="peer sr-only"
                              onChange={(e) => {
                                setFieldValue('isPublic', e.target.checked);
                                setIsPublic(e.target.checked);
                              }}
                            />
                            <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                            <span className="ml-5 text-sm font-medium text-gray-900">
                              Publique
                            </span>
                          </label>
                        </div>
                        <div>
                          <span className="my-3 block">
                            Du montant collecté
                          </span>
                          <label
                            htmlFor="montant-collecte"
                            className="relative inline-flex cursor-pointer items-center"
                            aria-label="Du montant collecté"
                          >
                            <input
                              type="checkbox"
                              checked={isAmountPublic}
                              id="montant-collecte"
                              className="peer sr-only"
                              onChange={(e) => {
                                setFieldValue(
                                  'isAmountPublic',
                                  e.target.checked
                                );
                                setIsAmountPublic(e.target.checked);
                              }}
                            />
                            <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300" />
                            <span className="ml-5 text-sm font-medium text-gray-900">
                              Public
                            </span>
                          </label>
                        </div>
                      </div>
                      <h2 className="my-10 text-center text-2xl text-gray-400/50">
                        Indiquez la date de fin de votre collecte
                      </h2>
                      <div className="mx-auto w-full max-w-lg">
                        <div className="my-5 flex items-start">
                          <span className="block w-full grow">
                            Durée à déterminer
                          </span>
                          <div>
                            <label
                              htmlFor="duree_a_determiner"
                              className="relative inline-flex cursor-pointer items-center"
                              aria-label="Durée à déterminer"
                            >
                              <input
                                type="checkbox"
                                checked={addEndingDate}
                                onChange={(e) => {
                                  setFieldValue(
                                    'hasEndingDate',
                                    e.target.checked
                                  );
                                  canDefine(e, 'endingDate');
                                }}
                                id="duree_a_determiner"
                                className="peer sr-only"
                              />
                              <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary" />
                            </label>
                          </div>
                        </div>
                        <div>
                          <div
                            ref={calendarDateRef}
                            role="button"
                            onClick={openCalendar}
                            className="relative flex w-full flex-col items-start overflow-x-auto sm:overflow-x-visible"
                            aria-hidden="true"
                          >
                            <div className="w-full">
                              <Field
                                name="endingDate"
                                type="date"
                                disabled={!addEndingDate}
                                className="relative mx-auto w-full items-start justify-center rounded-full border-2 border-primary p-6 text-base leading-tight"
                              />
                              {showCalendar && addEndingDate && (
                                <Calendar
                                  className="rounded shadow-md shadow-slate-200 sm:left-0 sm:top-full sm:z-10 lg:absolute"
                                  date={endingDate}
                                  onShownDateChange={(date) => {
                                    const currentDate = new Date(date);
                                    const currentBirthDate = new Date(date);
                                    // set birthDate to current date
                                    currentDate.setDate(
                                      endingDate.getDate() + 1
                                    );
                                    currentBirthDate.setDate(
                                      endingDate.getDate()
                                    );
                                    setFieldValue(
                                      'endingDate',
                                      currentDate.toISOString().split('T')[0]
                                    );
                                    setEndingDate(currentBirthDate);
                                    setShowCalendar(false);
                                  }}
                                  onChange={(date) => {
                                    const currentDate = new Date(date);
                                    // add 1 day to current date
                                    currentDate.setDate(
                                      currentDate.getDate() + 1
                                    );
                                    setFieldValue(
                                      'endingDate',
                                      currentDate.toISOString().split('T')[0]
                                    );
                                    setEndingDate(date);
                                    setShowCalendar(false);
                                  }}
                                  locale={fr}
                                  color="#FD6E56B7"
                                />
                              )}
                            </div>
                          </div>

                          {errors.endingDate && touched.endingDate ? (
                            <div className="text-sm text-red-400">
                              {errors.endingDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}

                  {step === 6 && (
                    <div className="relative mx-auto mt-16 w-full md:max-w-lg">
                      <p className="block w-full grow pb-4 text-center">
                        Vous pouvez décrire votre collecte et y ajouter des
                        medias si vous le souhaitez ultérieurement
                      </p>

                      <div className="max-w-[100%] rounded-md border border-gray-200">
                        <InternalEditor
                          setContent={(content) =>
                            setFieldValue('description', content)
                          }
                          content={JSON.stringify(initialContent)}
                        />
                      </div>
                    </div>
                  )}

                  <div className="mx-auto flex max-w-xl flex-wrap justify-between gap-2 mt-4">
                    {step === 7 ? (
                      <button
                        type="submit"
                        className={`relative mx-auto mt-6 w-fit items-start justify-center rounded-full bg-secondary px-9 py-4 text-base font-bold leading-tight text-white
                        ${!loading && 'duration-300 hover:scale-105'}`}
                      >
                        {loading ? 'En cours' : 'Lancer ma collecte'}
                      </button>
                    ) : (
                      <>
                        {step !== 0 && (
                          <button
                            type="button"
                            className="relative mx-auto w-full md:w-fit items-start justify-center rounded-full bg-gray-400 px-9 py-4 text-base font-bold leading-tight text-white duration-300 hover:scale-105 sm:mr-10"
                            onClick={handleBack}
                          >
                            Précédent
                          </button>
                        )}
                        <button
                          type="submit"
                          className={`button-glassmorphism relative mx-auto w-full md:w-fit items-start justify-center rounded-full px-9 py-4 text-base font-bold leading-tight text-white duration-300 hover:scale-105 disabled:opacity-50
                           ${step === 1 && 'mx-auto'}`}
                        >
                          {loading ? 'Vérification...' : 'Suivant'}
                        </button>
                      </>
                    )}
                  </div>
                  {message.length > 0 && (
                    <div
                      className={`mb-4 text-center text-sm ${
                        message[0] ? 'text-primary' : 'text-red-500'
                      }`}
                    >
                      {message[1]}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>

          <figure className="flex items-center justify-center lg:mb-16">
            <img
              src={stepsSharing[step - 1].slides[slide].src}
              alt={stepsSharing[step - 1].slides[slide].title}
              title={stepsSharing[step - 1].slides[slide].title}
              className="mx-auto  w-[362px]"
            />
          </figure>
        </div>
      </section>
    </div>
  );
};

export default NewCollection;
