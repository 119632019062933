/* eslint-disable */
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const SubHeader = ({ currentPage }) => {
  return (
    <nav className=" flex flex-col items-center justify-between px-6 py-7 shadow-md mb-4 md:mb-5">
      <ul className="flex flex-col w-full text-center sm:flex-row gap-4 md:gap-0 sm:shrink-0">
        <li className={` sm:mb-0 sm:mr-5 shrink-0 text-sm font-medium ${currentPage === 0 && 'text-primary'}`}>
          <Link to="/tableau-de-bord">Tableau de bord</Link>
        </li>
        <li
          className={`shrink-0 sm:mx-5 text-sm font-medium ${currentPage === 1 && 'text-primary'}`}
        >
          <Link to="/mon-profil">Mon profil</Link>
        </li>
        <li
          className={`shrink-0 sm:mx-5 text-sm font-medium ${currentPage === 2 && 'text-primary'}`}
        >
          <Link to="/mes-collectes">Mes collectes</Link>
        </li>
        <li
          className={`shrink-0 text-sm font-medium ${currentPage === 3 && 'text-primary'}`}
        >
          <Link to="/mes-supporters">Mes supporters</Link>
        </li>
      </ul>
      <ul className="mt-6 sm:mt-10 flex flex-col w-full items-center sm:flex-row sm:justify-end sm:space-x-4 xl:mt-0 2xl:grow">
        <li className="w-full sm:w-auto mb-4 sm:mb-0">
          <Link to="/historique-de-mes-virements" className="block">
            <button
              type="button"
              className="button-glassmorphism w-full sm:w-auto relative items-start justify-center rounded-full px-4 py-4 text-sm font-bold leading-tight text-white duration-300 hover:scale-105 sm:px-9"
            >
              Mes virements
            </button>
          </Link>
        </li>
        <li className="w-full sm:w-auto">
          <Link to="/lancer-une-collecte" className="block">
            <button
              type="button"
              className="button-glassmorphism w-full sm:w-auto relative items-start justify-center rounded-full px-4 py-4 text-sm font-bold leading-tight text-white duration-300 hover:scale-105 sm:px-9"
            >
              Lancer une nouvelle collecte
            </button>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

SubHeader.propTypes = {
  currentPage: PropTypes.number,
};

export default SubHeader;
