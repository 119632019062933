import Header from 'components/Header';
import React from 'react';
import { Helmet } from 'react-helmet';

const NoMatch = () => {
  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="404 - KOUDPOUSS" />
      <Header />
      <div className="flex flex-col bg-white pb-12 pt-16">
        <div className="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center">
              <p className="text-9xl font-bold uppercase tracking-wide text-primary">
                404
              </p>
              <h1 className="mt-2 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                Page non trouvée
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
