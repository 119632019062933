import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from './Button';

const Footer = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [message, setMessage] = useState({});

  const listMenu = [
    {
      class: 'lg:col-span-1',
      name: 'Menu',
      column: [
        {
          name: '',
          link: '',
          items: [
            {
              name: 'Contact',
              link: '/contactez-nous',
            },
            {
              name: 'A propos de nous',
              link: '/a-propos-de-nous',
            },
            {
              name: 'Questions fréquentes',
              link: '/faq',
            },
            {
              name: 'Toutes les collectes',
              link: '',
            },
          ],
        },
        {
          name: '',
          link: '',
          items: [
            {
              name: 'Actualité Koud Pouss',
              link: '',
            },
            {
              name: 'Tarifs',
              link: '/tarifs',
            },
            {
              name: 'CGU',
              link: '',
            },
            {
              name: 'Politique de confidentialité',
              link: '/politique-de-confidentialite',
            },
          ],
        },
      ],
    },
    {
      class: 'lg:col-span-1',
      name: 'Nous contacter',
      column: [
        {
          name: '',
          link: '',
          items: [
            // {
            //   name: '101 avenue du Général Leclerc 75685 PARIS CEDEX 14',
            //   icon: 'fas fa-map-marker-alt',
            //   link: '',
            //   class: 'md:!w-full',
            // },
            {
              name: '0663151989',
              icon: 'fas fa-phone',
              link: 'tel:0663151989',
              class: 'md:!w-full',
            },
            {
              name: 'contact@koud-pouss.com',
              icon: 'fas fa-envelope',
              link: 'mailto:contact@koud-pouss.com',
              class: 'md:!w-full',
            },
          ],
        },
      ],
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name) {
      navigate(`/lancer-une-collecte/nom/${name}`);
    } else {
      setMessage({
        type: 'error',
        message: 'Veuillez saisir un titre pour votre collecte',
      });
    }
  };

  return (
    <footer className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <div className="w-full bg-gradient-to-br from-primary to-primary/80 px-8 py-12 lg:px-24">
        {/* Logo and Newsletter Section */}
        <div className="mb-12 grid grid-cols-1 gap-8 lg:grid-cols-12 lg:gap-12">
          <div className="lg:col-span-4">
            <img src="/logo-black.png" alt="Koudpouss" className="mb-6 h-16" />
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                name="name"
                type="text"
                placeholder="Donne un titre à ta collecte"
                className="w-full rounded-full border  bg-white px-4 py-3 text-gray-800 placeholder-gray-500 focus:border-transparent focus:ring-2 focus:ring-primary"
                onChange={(e) => setName(e.target.value)}
              />
              {message.type === 'error' && (
                <p className="text-sm text-red-400" role="alert">
                  {message.message}
                </p>
              )}
              <Button
                type="submit"
                text="C'est parti !"
                className="button-glassmorphism -mt-6 flex w-full items-center justify-center px-6 py-4 font-medium text-white xl:px-12"
              />
            </form>
          </div>

          {/* Menu Sections */}
          <div className="lg:col-span-8">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              {listMenu.map((section) => (
                <div
                  key={`section_${section.name}`}
                  className={`${section.class}`}
                >
                  <h3 className="mb-6 text-lg font-semibold text-white">
                    {section.name}
                  </h3>
                  {section.column.map((col) => (
                    <ul key={`col_${col.name}`} className="space-y-4">
                      {col.items.map((item) => (
                        <li key={`item_${item.name}`}>
                          {item.link ? (
                            <a
                              href={item.link}
                              className="flex items-center text-white transition duration-200 hover:underline"
                            >
                              {item.icon && (
                                <i
                                  className={`${item.icon} mr-2 text-white`}
                                  aria-hidden="true"
                                />
                              )}
                              <span>{item.name}</span>
                            </a>
                          ) : (
                            <span className="flex items-center text-white">
                              {item.icon && (
                                <i
                                  className={`${item.icon} mr-2 text-white`}
                                  aria-hidden="true"
                                />
                              )}
                              <span>{item.name}</span>
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Social Media and Copyright */}
        <div className="mt-12 border-t border-white/20 pt-8">
          <div className="flex flex-col items-center justify-between space-y-6 md:flex-row md:space-y-0">
            <div className="flex space-x-8">
              <a
                href="https://twitter.com/koudpouss"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white transition-all duration-300 hover:scale-110 hover:text-blue-400"
                aria-label="Twitter"
              >
                <i className="fab fa-twitter text-2xl" aria-hidden="true" />
              </a>
              <a
                href="https://www.instagram.com/koudpouss/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white transition-all duration-300 hover:scale-110 hover:text-pink-500"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram text-2xl" aria-hidden="true" />
              </a>
              <a
                href="https://www.facebook.com/koudpouss"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white transition-all duration-300 hover:scale-110 hover:text-blue-600"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-f text-2xl" aria-hidden="true" />
              </a>
            </div>
            <p className="text-sm font-medium text-white/90">
              © {new Date().getFullYear()} Koud Pouss. Tous droits réservés.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
