/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/*eslint-disable */
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { getStats } from 'api/user';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import Header from 'components/Header';
import SubHeader from 'components/SubHeader';
import { fr } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '../components/ui/skeleton';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Déplacer StatCard en dehors du composant Dashboard
const StatCard = ({ title, value, loading, bgColor = 'bg-primary' }) => (
  <li className="max-w-xs">
    <div className={`relative mt-10 select-none before:absolute before:-bottom-[10px] before:left-10 before:h-0 before:w-0 before:border-x-[10px] before:border-t-[10px] before:border-x-transparent before:border-t-primary`}>
      <article className={`relative mx-auto w-64 rounded-md ${bgColor} pb-8 pl-4 pt-6`}>
        {loading ? (
          <>
            <Skeleton className="h-8 w-24" />
            <Skeleton className="mt-2 h-4 w-32" />
          </>
        ) : (
          <>
            <h2 className="text-2xl">{value}</h2>
            <p>{title}</p>
          </>
        )}
      </article>
    </div>
    {/* <figure className="relative mx-auto mt-4 w-10/12">
      <img src="/assets/wisely_title.webp" alt={title} />
    </figure> */}
  </li>
);

StatCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loading: PropTypes.bool.isRequired,
};

const Dashboard = () => {
  const navigate = useNavigate();
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };

  const labels = [
    '24 mars',
    '25 mars',
    '26 mars',
    '27 mars',
    '28 mars',
    '29 mars',
    '30 mars',
  ];

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: [0, 0, 0, 0, 0, 0, 0],
        borderColor: '#E6B139',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  const [period, setPeriod] = useState('1w');
  const [stats, setStats] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const calendarStartDateRef = React.useRef(null);
  const calendarEndDateRef = React.useRef(null);

  // Gestionnaire de clic extérieur pour les calendriers
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarStartDateRef.current &&
        !calendarStartDateRef.current.contains(event.target)
      ) {
        setShowStartCalendar(false);
      }
      if (
        calendarEndDateRef.current &&
        !calendarEndDateRef.current.contains(event.target)
      ) {
        setShowEndCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const redirect = () => {
    const location = window.location.href;
    setTimeout(() => {
      navigate(`/se-connecter?redirect=${location}`);
    }, 500);
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    redirect();
  };

  const fetchStats = async () => {
    if (!startDate || !endDate) {
      console.error('Les dates de début et de fin doivent être définies.');
      return;
    }

    setIsLoading(true);
    try {
      // Ajuster les dates pour la requête
      const adjustedStartDate = new Date(startDate);
      adjustedStartDate.setHours(0, 0, 0, 0);

      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setHours(23, 59, 59, 999);

      const res = await getStats(adjustedStartDate, adjustedEndDate);
      const statsData = res.data.data;
      setStats({
        donations: statsData.total_amount_dons,
        dailyDonations: statsData.total_amount_dons_of_day,
        supporters: statsData.total_supporter,
      });
    } catch (err) {
      console.error('Erreur lors de la récupération des statistiques:', err);
      if (err?.response?.status === 403) {
        logOut();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (date, isStart) => {
    const newDate = new Date(date);
    newDate.setHours(
      isStart ? 0 : 23,
      isStart ? 0 : 59,
      isStart ? 0 : 59,
      isStart ? 0 : 999
    );

    if (isStart) {
      setStartDate(newDate);
      setShowStartCalendar(false);
    } else {
      setEndDate(newDate);
      setShowEndCalendar(false);
    }
  };

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
    const today = new Date();
    today.setHours(23, 59, 59, 999);

    let newStartDate = new Date();
    newStartDate.setHours(0, 0, 0, 0);

    switch (newPeriod) {
      case '1w':
        newStartDate.setDate(today.getDate() - 6);
        break;
      case '2w':
        newStartDate.setDate(today.getDate() - 13);
        break;
      case '1m':
        newStartDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 29
        );
        break;
      case '3m':
        newStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          today.getDate()
        );
        break;
      case '6m':
        newStartDate = new Date(
          today.getFullYear(),
          today.getMonth() - 6,
          today.getDate()
        );
        break;
      case '1a':
        newStartDate = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
        break;
      default:
        return;
    }

    setStartDate(newStartDate);
    setEndDate(today);
  };

  // Initialisation
  useEffect(() => {
    handlePeriodChange('1w');
  }, []);

  // Mise à jour des stats quand les dates changent
  useEffect(() => {
    if (startDate && endDate) {
      fetchStats();
    }
  }, [startDate, endDate]);

  console.log('Statistiques actuelles:', stats);

  return (
    <div className="container mx-auto max-w-7xl md:px-8 lg:px-24">
      <Helmet title="Mon compte - KOUDPOUSS" />

      <Header />
      <section className="bg-white px-6 pb-10 pt-6">
        {/* Menus */}
        <SubHeader currentPage={0} />
        {/* Resume */}
      {/*   <nav className="flex flex-col items-start  sm:flex-row sm:items-center sm:px-6">
          <span className="mb-4 sm:mb-0 sm:mr-12">Résumé</span>
          <ul className="flex w-full flex-wrap gap-2 sm:w-auto sm:space-x-8">
            {[
              { id: '1w', label: '1S' },
              { id: '2w', label: '2S' },
              { id: '1m', label: '1M' },
              { id: '3m', label: '3M' },
              { id: '6m', label: '6M' },
              { id: '1a', label: '1A' },
            ].map((item) => (
              <li
                key={item.id}
                role="button"
                className={`inline-flex cursor-pointer items-center gap-2 rounded-lg bg-transparent px-2 py-2 text-center text-sm font-medium text-gray-500 hover:text-primary sm:px-4 sm:py-3 sm:text-base ${
                  period === item.id ? 'text-primary ' : 'text-gray-500'
                }`}
                onClick={() => handlePeriodChange(item.id)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </nav> */}
        {/* Dates */}
        {/* <form
          className="mt-6 flex flex-col items-stretch gap-4 px-2 sm:pl-6 md:justify-end"
          onSubmit={(e) => {
            e.preventDefault();
            fetchStats();
          }}
        >
          <div className="flex min-w-0 flex-col gap-4 sm:flex-row sm:items-center">
            <div className="flex min-w-0 items-center gap-2">
              <span className="shrink-0">Du</span>
              <div
                ref={calendarStartDateRef}
                className="relative min-w-0 flex-1"
              >
                <button
                  type="button"
                  onClick={() => setShowStartCalendar(true)}
                  className="w-full truncate rounded-full border-2 border-slate-300 px-3 py-2 text-sm sm:px-4 sm:py-3 sm:text-base"
                >
                  {startDate.toLocaleDateString('fr-FR')}
                </button>
                {showStartCalendar && (
                  <div className="absolute left-0 top-full z-10 mt-2">
                    <Calendar
                      className="rounded shadow-md shadow-slate-200"
                      date={startDate}
                      onChange={(date) => handleDateChange(date, true)}
                      maxDate={endDate}
                      locale={fr}
                      color="#FD6E56B7"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="flex min-w-0 items-center gap-2">
              <span className="shrink-0">au</span>
              <div
                ref={calendarEndDateRef}
                className="relative min-w-0 flex-1"
              >
                <button
                  type="button"
                  onClick={() => setShowEndCalendar(true)}
                  className="w-full truncate rounded-full border-2 border-slate-300 px-3 py-2 text-sm sm:px-4 sm:py-3 sm:text-base"
                >
                  {endDate.toLocaleDateString('fr-FR')}
                </button>
                {showEndCalendar && (
                  <div className="absolute left-0 top-full z-10 mt-2">
                    <Calendar
                      className="rounded shadow-md shadow-slate-200"
                      date={endDate}
                      onChange={(date) => handleDateChange(date, false)}
                      minDate={startDate}
                      maxDate={new Date()}
                      locale={fr}
                      color="#FD6E56B7"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="w-full sm:w-auto">
            <button
              type="submit"
              className="button-glassmorphism w-full rounded-full py-3 text-white sm:w-36"
            >
              Filtrer
            </button>
          </div>
          </form> */}
        {/* {isLoading && <div className="loader">Chargement...</div>} */}
        {/* Amounts */}
        <ul className="flex flex-col flex-wrap items-center justify-evenly gap-5 text-white sm:flex-row sm:px-2">
          <StatCard
            title="Dons totaux collectés"
            value={`${stats.donations || 0} €`}
            loading={isLoading}
             
          />
          <StatCard
            title="Dons collectés aujourd'hui"
            value={`${stats.dailyDonations || 0} €`}
            loading={isLoading}
       bgColor="bg-[#60a5fa]"
          />
          <StatCard
            title="Supporters"
            value={stats.supporters || 0}
            loading={isLoading}
            bgColor="bg-pink-500/50"
          />
        </ul>

        {/* Bouton vers mes collectes */}
        <div className="mt-8 flex justify-center px-4">
          <button
            onClick={() => navigate('/mes-collectes')}
            className="button-glassmorphism w-full rounded-full py-3 text-white sm:w-auto sm:px-8"
          >
            Voir mes collectes
          </button>
        </div>

        {/* Statistics */}
        {/* <section className="h-52">
          <Line options={options} data={data} />
        </section> */}
      </section>
    </div>
  );
};

export default Dashboard;
